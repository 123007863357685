import { Asset } from '@he-novation/config/types/asset.types';
import { AssetStatus } from '@he-novation/config/types/db/enums';
import { ROLE_OWNER } from '@he-novation/config/utils/acl';
import { __ } from '@he-novation/design-system/utils/i18n';
import { getSignedUrl } from '@he-novation/front-shared/async/asset.async';
import { asyncPublicCastSelect } from '@he-novation/front-shared/async/cast.async';
import {
    fetchFile,
    fetchSubtitles,
    fileEncryptedAccessTokenRequest
} from '@he-novation/front-shared/async/file.async';
import {
    fetchFolder,
    fetchFolderContent,
    fetchFolderPath,
    fetchFolderPlugins,
    folderTrashFetch
} from '@he-novation/front-shared/async/folder.async';
import { fetchNotes } from '@he-novation/front-shared/async/note.async';
import { fetchProjectTeams } from '@he-novation/front-shared/async/project.async';
import {
    fetchPlugins,
    fetchStorage,
    userLicensesFetch
} from '@he-novation/front-shared/async/user.async';
import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
import { officeLink } from '@he-novation/paths/herawFrontUris';
import { v4 as uuidV4 } from 'uuid';
import mapFetchNoteToUpdate from '../content/note/map/mapFetchNoteToUpdate';
import mapFetchTeamsToUpdate from '../content/projects/maps/mapFetchTeamsToUpdate';
import { mapFetchAssetsToUpdate } from './file/maps/mapFetchAssetsToUpdate';
import { mapFetchFileToUpdate } from './file/maps/mapFetchFileToUpdate';

export const fetchFileView = async ({ shared, uuid, version, versionToCompare }) => {
    let fileState: any = mapFetchFileToUpdate(await fetchFile({ uuid, version }));

    const [folder, plugins, breadcrumb] = await Promise.all([
        fetchFolder({ uuid: fileState.parent }),
        fetchFolderPlugins({ uuid: fileState.parent }),
        fetchFolderPath(fileState.parent).catch(() => [])
    ]);

    const folderState: Partial<FolderState> = { folder };

    fileState.breadcrumb = breadcrumb.map((b) => ({ ...b, shared }));
    fileState.breadcrumb.push({ name: fileState.name, shared });
    if (shared) fileState.breadcrumb.unshift({ name: __('SHARED'), href: '/shared' });

    fileState.highlightedVersion = version;

    folderState.plugins = plugins;

    const projectState = {
        currentProject: {}
    };
    if (folder.project) {
        const teams = await fetchProjectTeams(folder.project.uuid);
        projectState.currentProject = {
            uuid: folder.project.uuid,
            name: folder.project.name,
            ...mapFetchTeamsToUpdate(teams)
        };

        fileState.breadcrumb = fileState.breadcrumb.map((bc) => ({
            ...bc,
            projectUuid: folder.project!.uuid
        }));
    }

    if (fileState.type === 'office') {
        const asset = fileState.assets.find((asset: Asset) => asset.version === fileState.version);
        if (asset) {
            const signedUrl = await getSignedUrl(asset.uuid);
            fileState.source = officeLink(signedUrl);
        }
    } else {
        const m3u8: Asset | undefined =
            fileState.assets &&
            fileState.assets.find(
                ({ type, mimeType }) =>
                    mimeType === 'application/vnd.apple.mpegurl' && type === 'player'
            );

        if (m3u8 && m3u8.metadata?.encryption?.algorithm === 'aes-128') {
            fileState.encryptionRequestToken = uuidV4();
            fileState.encryptionAccessToken = await fileEncryptedAccessTokenRequest(
                fileState.encryptionRequestToken
            ).then(({ access_token }) => access_token);
        }

        fileState = {
            ...fileState,
            ...mapFetchAssetsToUpdate(
                fileState.type,
                fileState.assets,
                !!m3u8?.metadata?.encryption
            )
        };
    }

    if (typeof versionToCompare !== 'undefined') {
        fileState.comparisonAsset = fileState.assets.find(
            (asset: Asset) =>
                asset.type === 'player' &&
                asset.version === parseInt(versionToCompare as string) &&
                asset.url
        );

        if (fileState.type === 'video')
            fileState.comparisonSubtitles = await fetchSubtitles(fileState.uuid, versionToCompare);

        if (fileState.type === 'office' && fileState.comparisonAsset) {
            const signedUrl = await getSignedUrl(fileState.comparisonAsset.uuid);
            fileState.comparisonAsset.url = officeLink(signedUrl);
        }
    }

    fileState.activeAsset = fileState.assets.find(
        (asset: Asset) =>
            asset.type === 'player' &&
            asset.version === fileState.version &&
            asset.url &&
            asset.status === AssetStatus.READY
    );

    return {
        fileState,
        folderState,
        projectState,
        configState: {
            activeBranding: fileState.clientName
        }
    };
};

export const publicFileViewFetch = async ({
    uuid,
    version,
    public_password,
    castUid,
    castFolderUuid,
    castFileUuid
}: {
    uuid: string;
    version: number;
    public_password?: string | null;
    castUid?: string;
    castFolderUuid?: string | null;
    castFileUuid?: string;
}) => {
    const [f] = await Promise.all([
        fetchFile({ uuid, version, public_password, isFromCast: !!castUid })
    ]);
    let fileState: any = mapFetchFileToUpdate(f);
    fileState.castFileUuid = castFileUuid;

    if (castUid) {
        fileState.cast = await asyncPublicCastSelect(
            castUid,
            castFolderUuid,
            undefined,
            public_password
        );
    }

    if (fileState.type === 'office') {
        if (fileState.assets?.length > 0) {
            const signedUrl = await getSignedUrl(fileState.assets[0].uuid);
            fileState.source = officeLink(signedUrl);
        }
    } else {
        if (fileState.type === 'video')
            fileState.subtitles = await fetchSubtitles(
                fileState.uuid,
                fileState.version,
                public_password
            );
        const m3u8 =
            fileState.a && fileState.a.find(({ metadata }) => metadata?.encryption === 'm3u8');

        if (m3u8) {
            fileState.encryptionRequestToken = uuidV4();
            fileState.encryptionAccessToken = await fileEncryptedAccessTokenRequest(
                fileState.encryptionRequestToken
            ).then(({ access_token }) => access_token);
        }

        fileState = {
            ...fileState,
            ...mapFetchAssetsToUpdate(fileState.type, fileState.a, m3u8)
        };
    }

    const folderState = castUid ? {} : {};

    return {
        fileState,
        folderState
    };
};

export const fetchFolderView = async (
    folderUuid: string,
    clientName: string,
    isSharedRoot?: boolean
) => {
    const folderData = isSharedRoot ? null : await fetchFolder({ uuid: folderUuid });
    let folderState: any = {};
    const shared = isSharedRoot || folderData?.role !== ROLE_OWNER;

    if (!(shared && !folderUuid) && !folderData?.uuid) {
        folderState = { error: __('ERR_FOLDER_NOT_FOUND_OR_UNAUTHORIZED') };
    } else {
        if (folderUuid || !shared) folderState.folder = folderData;
        else
            folderState.folder = {
                name: __('SHARED'),
                folder: null,
                isPublic: false,
                uuid: null,
                castTriggers: [],
                labels: []
            };

        folderState.error = null;

        folderState.isSharedRoot = !folderUuid && shared;

        await Promise.all(
            [
                (folderUuid ? fetchFolderPlugins({ uuid: folderUuid }) : fetchPlugins()).then(
                    (plugins) => (folderState.plugins = plugins)
                ),
                fetchFolderContent({ uuid: folderUuid, shared }).then(
                    (content) => (folderState.content = content)
                )
            ].filter((f) => !!f)
        );
    }

    return {
        folderState,
        configState: {
            activeBranding: folderState.isSharedRoot
                ? clientName
                : folderState?.folder?.client?.name || 'private'
        }
    };
};

export const fetchSettingsView = async () => {
    const storage = await fetchStorage(undefined, true);
    const licenses = await userLicensesFetch();

    const userState = {
        storage,
        licenses
    };

    return { userState };
};

export const fetchTrashView = async (folderUuid?: string) => {
    const folderState: any = {};
    folderState.isRoot = !folderUuid;
    if (!folderUuid) {
        const trashRoot = await folderTrashFetch();
        folderUuid = trashRoot.uuid;
        folderState.parent = null;
    } else {
        const folder = await fetchFolder({ uuid: folderUuid });
        folderState.name = folder.name;
        folderState.parent = folder.folder?.uuid;
    }

    folderState.content = await fetchFolderContent({ uuid: folderUuid, shared: false });

    return { folderState };
};
