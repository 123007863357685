import './DataLayoutChildren.scss';
import React, { CSSProperties } from 'react';
import cn from 'classnames';

import { DataLayoutGridItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutGridItem/DataLayoutGridItem';
import { DataLayoutListItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutListItem/DataLayoutListItem';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import { DataLayoutDisplayMode, ItemComponent } from '$components/DataLayout/DataLayout.types';
import { SelectionOptions } from '$components/Selectable/SelectionHandler';

type DataLayoutChildrenProps<T, U extends SelectionAtom | undefined, V> = {
    items: T[];
    displayMode: DataLayoutDisplayMode;
    computeItemStyle?: (index: number) => CSSProperties;
    offset: number;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    style?: React.CSSProperties;
    group?: string;
    ActionsMenuComponent?: React.ComponentType<any>;
    selectionAtom: U;
    extra: V;
    hideHeader?: boolean;
    itemToId: (item: T) => string;
};

function DataLayoutChildrenInner<T, U extends SelectionAtom | undefined, V>({
    items,
    computeItemStyle,
    displayMode,
    columns,
    ItemGridComponent,
    itemToId,
    style,
    offset,
    group,
    selectionAtom,
    ActionsMenuComponent,
    extra,
    hideHeader
}: DataLayoutChildrenProps<T, U, V>) {
    const Component =
        ItemGridComponent && displayMode === DataLayoutDisplayMode.Grid
            ? DataLayoutGridItem
            : DataLayoutListItem;

    return (
        <ul
            className={cn(
                'data-layout-children',
                displayMode === DataLayoutDisplayMode.List && !hideHeader && 'has-header'
            )}
            style={style}
        >
            {items.map((item, i) => {
                return (
                    <DataLayoutChild
                        selectionAtom={selectionAtom}
                        key={`${i}-${offset}-${displayMode}-${itemToId(item)}`}
                        i={i + offset}
                        offset={offset}
                        item={item}
                        extra={extra}
                        columns={columns}
                        Component={Component}
                        //@ts-expect-error weird
                        ItemGridComponent={ItemGridComponent}
                        style={computeItemStyle?.(i + offset) || {}}
                        group={group}
                        ActionsMenuComponent={ActionsMenuComponent}
                        displayMode={displayMode}
                        itemToId={itemToId}
                    />
                );
            })}
        </ul>
    );
}

type DataLayoutChildProps<T, U extends SelectionAtom | undefined, V> = {
    i: number;
    offset: number;
    item: T;
    extra: V;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    Component: ItemComponent<T, U, V>;
    selectionAtom: U;
    selectionOptions?: SelectionOptions;
    style?: React.CSSProperties;
    group?: string;
    ActionsMenuComponent?: React.ComponentType<any>;
    displayMode: DataLayoutDisplayMode;
    itemToId: (item: T) => string;
};
function DataLayoutChild<T, U extends SelectionAtom | undefined, V>({
    i,
    offset,
    item,
    extra,
    columns,
    ItemGridComponent,
    Component,
    selectionAtom,
    style,
    ActionsMenuComponent,
    displayMode,
    itemToId
}: DataLayoutChildProps<T, U, V>) {
    const componentProps = {
        index: i,
        item,
        extra,
        columns,
        ActionsMenuComponent,
        ItemGridComponent
    };

    const id = itemToId(item);

    return (
        <li
            key={`${i}-${offset}-${displayMode}`}
            className={cn(
                'li-' + i + '-' + offset,
                i % 2 ? 'is-odd' : 'is-even',
                DATA_LAYOUT_CLASSES.CHILD
            )}
            data-id={id}
            style={style}
        >
            <Component {...componentProps} id={id} selectionAtom={selectionAtom} />
        </li>
    );
}

export const DataLayoutChildren = React.memo(DataLayoutChildrenInner);
