import NoteComment from './NoteComment';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { ATTACHMENT, KEY_WORD } from '@he-novation/paths/modals.constants';
import { openModal } from '$redux/route/routeActions';
import { TYPE_NOTE } from '$constants/constants.note';
import combineSelectors from '$helpers/combineSelectors';
import {
    fileTypeSelector,
    fileUuidSelector,
    fileVersionSelector,
    folderUuidSelector,
    heightAndWidthSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import {
    currentUserSelector,
    currentUserUuidSelector,
    commentRightsSelector
} from '$redux/user/userSelectors';
import { __ } from '@he-novation/design-system/utils/i18n';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { folderRoleSelector, isSharedSelector } from '$redux/content/folder/folderSelectors';

export default compose(
    connect(
        combineSelectors(
            fileUuidSelector,
            fileTypeSelector,
            folderUuidSelector,
            fileVersionSelector,
            heightAndWidthSelector,
            currentUserSelector,
            currentUserUuidSelector,
            pageAndPageNumberSelector,
            isSharedSelector,
            folderRoleSelector,
            commentRightsSelector
        ),
        (dispatch) => ({
            openAttachmentsModal: (callback, attachments) =>
                dispatch(
                    openModal(ATTACHMENT, {
                        title: __('REACT_ATTACHED_FILES'),
                        callback,
                        attachments
                    })
                ),
            openKeywordsModal: (callback, keywords) =>
                dispatch(openModal(KEY_WORD, { title: __('REACT_KEYWORDS'), callback, keywords })),
            toggleKeyboardListeners: (active) => dispatch(toggleKeyboardListeners(active))
        })
    )
)(NoteComment);
