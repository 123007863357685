import './SidePanelFilters.scss';
import React, { useState } from 'react';
import {
    FormFieldTypes,
    StyledFormField
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import cn from 'classnames';
import { useAtom } from 'jotai';
import { displayAllVersionsAtom } from '../../atoms/note-atoms';
import FiltersCheckboxList from '../Filters/FiltersCheckboxList';

type FilterValues = {
    [key: string]: { label: string; value: any }[];
};
type SidePanelFiltersProps = {
    selectedFilters: { [key: string]: any[] };
    allowSingle?: boolean;
    filters: { key: string; label: string; allowSingle?: boolean }[];
    values: {
        [key: string]: { label: string; value: any }[];
    };
    onFilter: (filters: FilterValues) => void;
    sorters?: string[];
    sorter: string | null;
    setSorter: (sorter: string) => void;
    hasFolderPluginNotesAllVersions: boolean;
};

const SidePanelFilters: React.FC<SidePanelFiltersProps> = ({
    selectedFilters,
    filters: _filters,
    values,
    allowSingle,
    onFilter,
    sorters = [],
    sorter,
    setSorter,
    hasFolderPluginNotesAllVersions
}) => {
    const [filters, setFilters] = useState(
        selectedFilters
            ? selectedFilters
            : _filters.reduce((acc, curr) => {
                  acc[curr.key] = [];
                  return acc;
              }, {})
    );

    const [activeTab, setActiveTab] = useState<string | null>(null);
    const [displayAllVersions, setDisplayAllVersions] = useAtom(displayAllVersionsAtom);

    return (
        <div className="c-side-panel-filters">
            {hasFolderPluginNotesAllVersions && (
                <div className="c-side-panel-filters-show-all-versions">
                    <StyledFormField
                        type={FormFieldTypes.Checkbox}
                        theme={Theme.Dark}
                        checked={displayAllVersions}
                        onChange={() => setDisplayAllVersions((dv) => !dv)}
                        label={__('SHOW_ALL_VERSION_NOTES')}
                        disabled={false}
                        id={'showAllVersionsHtmlId'}
                    />
                </div>
            )}
            {sorters.length > 0 && (
                <ul className="c-side-panel-filters-sorters">
                    {sorters.map((s) => (
                        <li key={s}>
                            <button
                                className={s !== sorter ? 'is-inactive' : undefined}
                                type="button"
                                onClick={() => setSorter(s)}
                            >
                                {__('SORTER_' + s.toUpperCase())}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            <ul className="c-side-panel-filters-types">
                {_filters.map(({ label, key, allowSingle: filterAllowSingle }, i) => (
                    <li key={i} className={cn({ 'is-active': activeTab === key })}>
                        <button
                            disabled={
                                values[key].length <= (allowSingle || filterAllowSingle ? 0 : 1)
                            }
                            type="button"
                            onClick={() => setActiveTab(activeTab === key ? null : key)}
                        >
                            {values[key].length} {label}
                        </button>
                    </li>
                ))}
            </ul>
            {activeTab && (
                <FiltersCheckboxList
                    fieldClassName="theme-light"
                    onChange={(filterName, e) => {
                        const f: FilterValues = {
                            ...filters,
                            [activeTab]: filters[activeTab].includes(e.target.value)
                                ? filters[activeTab].filter((val) => val !== e.target.value)
                                : [...filters[activeTab], e.target.value]
                        };
                        setFilters(f);
                        onFilter?.(f);
                    }}
                    list={values[activeTab]}
                    filterName={activeTab}
                    filters={filters}
                />
            )}
        </div>
    );
};

export default SidePanelFilters;
