import {
    AuthClientLoginPayload,
    AuthLoginPayload,
    AuthPasswordUpdatePayload
} from '@he-novation/config/types/payloads/auth.payload';
import { PluginConfigurationCreationSchema } from '@he-novation/config/types/payloads/plugin.payload';
import {
    UserInfoSchema,
    UserPreferencesSchema,
    UserStorageQuery
} from '@he-novation/config/types/payloads/user.payload';
import { PublicPluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { UserStorage } from '@he-novation/config/types/user.types';
import paths, {
    authPaths,
    pluginPaths,
    publicApiV1Paths,
    userPaths
} from '@he-novation/paths/herawApiPaths';
import { indexLink } from '@he-novation/paths/herawFrontUris';
import { loginClientLink, loginLink } from '@he-novation/paths/herawMarketingPaths';
import { apiFetch, fetchAsFormData } from './apiFetch';

export const asyncOIDCLogin = (username: string, password: string, provider: string) =>
    apiFetch(userPaths.oidcLogin, {
        method: 'POST',
        params: {
            provider
        },
        body: {
            username,
            password
        }
    });
export const asyncUserLogin = (email: string, password: string, twoFAKey?: string) =>
    apiFetch(authPaths.login, {
        method: 'POST',
        body: {
            email,
            password,
            totp: twoFAKey
        } satisfies AuthLoginPayload
    });
export const asyncClientLogin = (clientName: string, password: string, twoFAKey?: string) =>
    apiFetch(authPaths.loginClient, {
        method: 'POST',
        body: {
            clientName,
            password,
            totp: twoFAKey
        } satisfies AuthClientLoginPayload
    });

export const fetchUserAppInfos = () => apiFetch(paths.users.appInfos);

export const requestDelete = (cb?: () => void) =>
    apiFetch(paths.users.root, {
        method: 'DELETE'
    }).then(() => typeof cb === 'function' && cb());

export const paymentCancelSubscription = () =>
    apiFetch(`api/payment/cancel/subscription`, {
        method: 'POST'
    });

export const fetchAccount = () =>
    apiFetch(paths.users.account, {
        method: 'GET'
    });

export const userLicensesFetch = () => apiFetch(paths.users.licenses);

export const fetchStorage = async (folderUuid?: string, sum?: boolean): Promise<UserStorage> =>
    apiFetch(userPaths.storage, { query: { folderUuid, sum } satisfies UserStorageQuery });

export const fetchPlugins = () => apiFetch(userPaths.plugins);

export const fetchPluginsEnabled = (): Promise<PublicPluginConfigurationType[]> =>
    apiFetch(userPaths.pluginConfigurations);

export const contactAdmin = async (message) =>
    apiFetch(`api/user/contact/admin`, {
        method: 'POST',
        body: JSON.stringify({
            message
        })
    });

export const updateProfile = async (profile: UserInfoSchema) =>
    apiFetch(paths.users.profile, {
        method: 'PUT',
        body: profile
    });

export const updatePassword = async (body: AuthPasswordUpdatePayload) =>
    apiFetch(authPaths.password, {
        method: 'PUT',
        body
    });

export const updateUserPreferences = async (body: UserPreferencesSchema) =>
    apiFetch(userPaths.preferences, {
        method: 'PATCH',
        body
    });

export const fetchGeoIp = async () =>
    window
        .fetch('https://api.ipstack.com/check?access_key=3098b60efb362a9194cf15bb592954c3')
        .then((r) => r.json());

export const cancelLicense = (userUuid: string) =>
    apiFetch(userPaths.license, {
        method: 'DELETE',
        body: {
            userUuid
        }
    });

export const updateFoldersLiveNotify = (livenotify) =>
    apiFetch(`api/user/update/livenotify`, {
        method: 'POST',
        body: JSON.stringify({ livenotify })
    });

export const signUp = async ({
    firstname,
    lastname,
    email,
    phone,
    firm,
    role,
    recaptcha,
    locale,
    plan,
    picture
}: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    firm: string;
    role: string;
    recaptcha: string;
    locale: string;
    plan: string;
    picture?: string;
}) =>
    apiFetch(paths.users.root, {
        method: 'POST',
        maxRedirects: 0,
        useRawError: true,
        body: {
            firstname,
            lastname,
            email,
            phone,
            firm,
            role,
            recaptcha,
            locale,
            plan,
            picture
        }
    })
        .then((r) => {
            window.location.href = process.env.APP_URL + indexLink();
        })
        .catch((e) => {
            if (e.response?.data?.message) {
                switch (e.response?.data?.message) {
                    case 'ERR_ACCOUNT_ALREADY_EXISTS':
                        return (window.location.href = loginLink(locale, email));
                }
            }
        });

export const logout = (locale?: string, isClient?: boolean) =>
    apiFetch(authPaths.logout, { method: 'POST' }).then((r) => {
        window.location.href =
            process.env.MARKETING_URL + (isClient ? loginClientLink(locale) : loginLink(locale));
    });

export const requestAdmittance = ({ signupData, folderUuid }) =>
    apiFetch(paths.folder.admittance, {
        method: 'POST',
        redirect: false,
        params: { folderUuid },
        body: {
            signupData
        }
    });

export const asyncTranslationCreditsFetch = () => apiFetch(userPaths.translationCredits);

export const asyncOpenApiUserFetch = async (email: string, password: string) =>
    apiFetch(publicApiV1Paths.auth.user, {
        method: 'POST',
        body: {
            email,
            password
        } satisfies AuthLoginPayload
    });
