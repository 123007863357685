import { AreaSelectionState } from '@he-novation/config/types/area-selection.types';

import { mapAreaSelectionToMetadata } from '$components/Controls/AreaSelection/AreaSelection.helpers';

export default ({
    activeAudioTrack,
    activeSubtitles,
    tcIn,
    tcOut,
    areaSelection,
    height,
    width,
    frame,
    page
}: {
    activeAudioTrack?: string;
    activeSubtitles?: string | string[];
    tcIn: string;
    tcOut?: string;
    areaSelection?: AreaSelectionState;
    height: number;
    width: number;
    frame: number;
    page?: number;
}) => ({
    activeAudioTrack: tcIn ? activeAudioTrack : undefined,
    activeSubtitles: tcIn ? activeSubtitles : undefined,
    tcIn,
    tcOut,
    original: {
        ImageHeight: height,
        ImageWidth: width
    },
    frame,
    page,
    ...(areaSelection ? mapAreaSelectionToMetadata(areaSelection) : {})
});
