import { useAtom } from 'jotai/index';

import { SelectionAtom } from '$components/DataLayout/DataLayout.types';

export function useDataLayoutChildSelection(selectionAtom: SelectionAtom | undefined) {
    const [selected, setSelected] = selectionAtom
        ? useAtom(selectionAtom)
        : [[], (_keys: string[]) => {}];
    return {
        isSelected(id: string) {
            return selected.includes(id);
        },
        toggle(id: string) {
            setSelected((selected) => {
                if (selected.includes(id)) {
                    return selected.filter((key) => key !== id);
                }
                return [...selected, id];
            });
        },
        select(id: string) {
            setSelected((s) => {
                if (s.includes(id)) return s;
                return [...s, id];
            });
        },
        shiftSelect(items: HTMLElement[], id: string) {
            const index = items.findIndex((item) => item.dataset.id === id);
            setSelected((selected) => {
                const selectedIndexes = selected.map((s) =>
                    items.findIndex((i) => i.dataset.id === s)
                );
                const closestIndex = selectedIndexes.reduce(
                    (acc, i) => (Math.abs(index - i) < acc ? i : acc),
                    selectedIndexes[0]
                );
                const start = Math.min(index, closestIndex);
                const end = Math.max(index, closestIndex);
                const _selected = [...selected];
                for (let i = start; i <= end; i++) {
                    if (!_selected.includes(items[i].dataset.id!)) {
                        _selected.push(items[i].dataset.id!);
                    }
                }
                return _selected;
            });
        }
    };
}
