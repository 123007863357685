import './NoteComment.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FolderRole } from '@he-novation/config/types/folder.types';
import type { Comment } from '@he-novation/config/types/note.types';
import { ROLE_MANAGER, ROLE_OWNER } from '@he-novation/config/utils/acl';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { Theme } from '@he-novation/design-system/enums';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { selectedNoteAtom } from '../../../atoms/note-atoms';
import { useCreateNotesAndComments } from '../../../hooks/useNotes';
import LineBreakString from '../../base/LineBreakString/LineBreakString';
import NoteButtons from '../../Notes/NoteButtons/NoteButtons';
import EditCommentForm from '../../Notes/NoteComment/forms/EditCommentForm';

import { getLocalizedTime } from '$helpers/datetime';

type NoteCommentProps = {
    comment: Omit<Comment, 'note' | 'file'>;
    folderRole: FolderRole;
    currentUserUuid: string;
    commentRights: any;
    theme?: Theme;
    locale: string;
    openAttachmentsModal: () => void;
    openKeywordsModal: () => void;
    toggleKeyboardListeners: (enable: boolean) => void;
    editCommentAndCreateTask: (payload: any, task: any, callback: () => void) => void;
};

export default function NoteComment({
    comment,
    folderRole,
    commentRights,
    currentUserUuid,
    theme,
    locale,
    openAttachmentsModal,
    openKeywordsModal,
    toggleKeyboardListeners
}: NoteCommentProps) {
    const dispatch = useDispatch();
    const [tags, setTags] = useState(comment.tags);
    const [attachments, setAttachments] = useState<File[]>();
    const [canDelete, setCanDelete] = useState(false);
    const [canModify, setCanModify] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isImportant, setIsImportant] = useState(comment.isImportant);
    const formCommentRef = useRef();
    const selectedNote = useAtomValue(selectedNoteAtom);
    const isDraftRef = useRef(comment.draft);
    const { commentEdit, commentDelete } = useCreateNotesAndComments();

    useEffect(() => {
        setTags(comment.tags);

        let canDelete = false;
        let canModify = false;
        if (comment.draft || [ROLE_MANAGER, ROLE_OWNER].includes(folderRole)) {
            canDelete = true;
        } else if (currentUserUuid === comment.user?.uuid && commentRights?.comment_delete) {
            const deleteUntil = new Date(
                comment.created.getTime() + commentRights.comments_delete_timeout * 1000
            ).getTime();
            canDelete = Date.now() < deleteUntil;
        }

        if (!isEditing && currentUserUuid === comment.user?.uuid) {
            if (comment.draft) {
                canModify = true;
            } else if (commentRights?.comments_modify) {
                const modifyUntil = new Date(
                    comment.created.getTime() + commentRights.comments_modify_timeout * 1000
                ).getTime();
                canModify = Date.now() < modifyUntil;
            }
        }

        setCanDelete(canDelete);
        setCanModify(canModify);
    }, [comment.uuid]);

    const actions: MultiActionsList = [];

    if (canModify) {
        actions.push({
            children: __('EDIT'),
            onClick: () => setIsEditing(true)
        });
    }
    if (canDelete) {
        actions.push({
            children: __('DELETE'),
            onClick: () => commentDelete(selectedNote!.uuid, comment.uuid)
        });
    }
    return (
        <div className={cn('c-note-comment', theme)}>
            <div
                className={cn('wrapper-content', {
                    'is-important': isImportant,
                    'is-editing': isEditing
                })}
            >
                {isEditing && (
                    <EditCommentForm
                        isDraft={comment.draft}
                        openAttachmentsModal={openAttachmentsModal}
                        openKeywordsModal={openKeywordsModal}
                        attachments={comment.assets.length}
                        setAttachments={setAttachments}
                        onClick={(e) => {
                            isDraftRef.current = e.currentTarget.id === 'comment-draft-submit';
                        }}
                        keywords={comment.tags}
                        setKeywords={setTags}
                        disableKeyboardListeners={() => toggleKeyboardListeners(false)}
                        enableKeyboardListeners={() => toggleKeyboardListeners(true)}
                        formCommentRef={formCommentRef}
                        onSubmit={(e, { content }) => {
                            if (e) e.preventDefault();
                            commentEdit(selectedNote!.uuid, comment.uuid, {
                                content,
                                attachments,
                                tags,
                                isDraft: isDraftRef.current
                            }).then(() => {
                                setIsEditing(false);
                            });
                        }}
                        content={comment.content}
                    />
                )}
                {!isEditing && comment.content && (
                    <div className="content">
                        <LineBreakString string={comment.content} parseLinks />
                    </div>
                )}
                {!isEditing && (
                    <NoteButtons
                        theme={theme}
                        attachments={comment.assets}
                        keywords={tags}
                        isDraft={comment.draft}
                    />
                )}

                {!isEditing && actions.length > 0 && (
                    <MultiActionButton
                        icon="three-dots"
                        direction={[DirectionX.Left, DirectionY.Middle]}
                        actions={actions}
                    />
                )}
            </div>
            <div className="info-comment">
                <span>{comment.created && getLocalizedTime(locale, comment.created, 'hour')}</span>
                <Icon icon="check-double" />
            </div>
        </div>
    );
}
