import { apiFetch } from './apiFetch';

export const verifyEmailOrPassword = (recaptcha: string, email: string, password?: string) =>
    apiFetch('/transfer/verify', {
        method: 'POST',
        body: JSON.stringify({
            username: email,
            password,
            recaptcha
        })
    });
