import './DataLayoutGridItem.scss';
import React from 'react';
import cn from 'classnames';

import { useDataLayoutChildSelection } from '$components/DataLayout/components/DataLayoutChildren/useDataLayoutChildSelection';
import { ItemComponent, SelectionAtom } from '$components/DataLayout/DataLayout.types';

type DataLayoutItemGridProps<T, U extends SelectionAtom | undefined, V> = {
    id: string;
    item: T;
    ItemGridComponent: ItemComponent<T, U, V>;
    selectionAtom: U;
    extra: V;
    className: string;
};
export function DataLayoutGridItem<T, U extends SelectionAtom | undefined, V>({
    id,
    item,
    ItemGridComponent,
    selectionAtom,
    extra,
    className
}: DataLayoutItemGridProps<T, U, V>) {
    const { isSelected } = useDataLayoutChildSelection(selectionAtom);
    return (
        <div
            id={id}
            className={cn('data-layout-grid-item', isSelected(id) && 'is-selected', className)}
        >
            <ItemGridComponent id={id} item={item} selectionAtom={selectionAtom} extra={extra} />
        </div>
    );
}
