import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Asset } from '@he-novation/config/types/asset.types';
import { Comment, Note } from '@he-novation/config/types/note.types';
import { NoteCreateBody } from '@he-novation/config/types/payloads/note.payload';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { ContentUser } from '@he-novation/config/types/user.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    createComment,
    createNote as _createNote,
    deleteComment,
    deleteNote,
    editComment as _editComment,
    uploadAttachments
} from '@he-novation/front-shared/async/note.async';
import { signupLink } from '@he-novation/paths/herawFrontUris';
import { bytesToSize } from '@he-novation/utils/bytes';
import update, { Spec } from 'immutability-helper';
import { useAtom, useSetAtom } from 'jotai';
import { notesAtom } from '../atoms/note-atoms';
import { useSocketIO } from './useSocketIO';

import isJsonString from '$helpers/isJsonString';
import { contactsSelector } from '$redux/content/contacts/contactsSelectors';
import { fileUuidSelector } from '$redux/content/file/fileSelectors';
import sqlDatesToJsDates from '$redux/content/projects/maps/sqlDatesToJsDates';
import { selectedTeamSelector } from '$redux/content/projects/projectsSelector';
import { openFeedbackModal } from '$redux/route/routeActions';
import { userInfosSelector } from '$redux/user/userSelectors';

export function useNotes() {
    const { contacts } = useSelector(contactsSelector);
    const { userInfos } = useSelector(userInfosSelector);
    const { selectedTeam } = useSelector(selectedTeamSelector);

    const [notes, setNotes] = useAtom(notesAtom);

    const mappedNotes = useMemo(() => {
        const list = notes.map((n) => {
            const contact = contacts.list.find(({ email }) => email === n.user?.email);
            if (n.user && n.user.uuid === userInfos.uuid) {
                n.user.firstname = userInfos.firstname;
                n.user.lastname = userInfos.lastname;
                return n;
            }
            if (contact) n.user = contact;
            return n;
        });

        if (list.length && selectedTeam) {
            if (selectedTeam !== 'cast')
                return list.filter(({ team }) => team?.uuid === selectedTeam);
            else return list.filter(({ cast }) => cast);
        }
        return list;
    }, [notes, selectedTeam, contacts, userInfos]);

    return {
        notes: mappedNotes,
        setNotes
    };
}

export function useNoteSockets() {
    const { subscribe, unsubscribe } = useSocketIO();
    const setNotes = useSetAtom(notesAtom);

    return {
        unsubscribe,
        subscribe: (roomUuids: string[]) => {
            for (const uuid of roomUuids) {
                subscribe?.({
                    socket: 'note',
                    room: uuid,
                    actions: {
                        sioNoteCreate: ({ note, file, user }) => {
                            const _note = {
                                uuid: note.uuid,
                                file: file,
                                type: note.type,
                                assets: note.assets,
                                detailAsset: (
                                    (note.assets &&
                                        note.assets.find(({ quality }) => quality === 'detail')) ||
                                    {}
                                ).url,
                                metadata: note.metadata,
                                created: new Date(note.created),
                                team: note.team,
                                task: note.task ? sqlDatesToJsDates(note.task) : null,
                                user: user,
                                updated: new Date(),
                                comments: note.comments.map((c) => ({
                                    ...c,
                                    user: user,
                                    created: new Date(c.created)
                                }))
                            };

                            setNotes((notes) => {
                                const noteIndex = notes.findIndex(
                                    ({ uuid, comments }) =>
                                        uuid === _note.uuid ||
                                        (uuid === 'new' &&
                                            comments[0].content === _note.comments[0].content)
                                );
                                return update(
                                    notes,
                                    noteIndex > -1
                                        ? { [noteIndex]: { $merge: _note } }
                                        : { $unshift: [_note] }
                                );
                            });
                        },
                        sioNoteDelete: ({ noteUuid }: { noteUuid: string }) => {
                            setNotes((notes) => {
                                return update(notes, {
                                    $apply: (list) => list.filter(({ uuid }) => uuid !== noteUuid)
                                });
                            });
                        },
                        sioNoteUpdate: ({
                            content,
                            uuid,
                            task
                        }: {
                            content: string;
                            uuid: string;
                            task?: { status: TaskStatus };
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex((n) => n.uuid === uuid);
                                const _update: Spec<Note[], never> = {
                                    [noteIndex]: {
                                        comments: {
                                            [notes[noteIndex].comments.length - 1]: {
                                                content: { $set: content }
                                            }
                                        },
                                        task:
                                            notes[noteIndex].task && task
                                                ? {
                                                      description: { $set: content },
                                                      status: { $set: task.status }
                                                  }
                                                : undefined
                                    }
                                };
                                return update(notes, _update);
                            });
                        },
                        sioCommentCreate: ({
                            comment,
                            user
                        }: {
                            comment: Omit<Comment, 'file' | 'user'>;
                            user: ContentUser;
                        }) => {
                            setNotes((notes) => {
                                const index = notes.findIndex(
                                    ({ uuid }) => comment?.note?.uuid === uuid
                                );
                                if (!notes[index]) return notes;
                                const commentIndex = notes[index].comments.findIndex(
                                    (c) => c.uuid === comment.uuid
                                );
                                if (commentIndex > -1) return notes;
                                return update(notes, {
                                    [index]: {
                                        comments: {
                                            $unshift: [
                                                {
                                                    user,
                                                    ...comment,
                                                    created: new Date(comment.created)
                                                }
                                            ]
                                        }
                                    }
                                });
                            });
                        },
                        sioCommentDelete: ({
                            noteUuid,
                            uuid
                        }: {
                            noteUuid: string;
                            uuid: string;
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                                if (notes[noteIndex].comments.length === 1) {
                                    return update(notes, { $splice: [[noteIndex, 1]] });
                                }
                                return update(notes, {
                                    [noteIndex]: {
                                        comments: {
                                            $splice: [
                                                [
                                                    notes[noteIndex].comments.findIndex(
                                                        (c) => c.uuid === uuid
                                                    ),
                                                    1
                                                ]
                                            ]
                                        }
                                    }
                                });
                            });
                        },
                        sioNoteAttachment: ({
                            asset,
                            comment,
                            note
                        }: {
                            asset: Asset;
                            comment: { uuid: string };
                            note: { uuid: string };
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex(({ uuid }) => uuid === note.uuid);
                                if (noteIndex === -1) return notes;
                                const commentIndex = notes[noteIndex].comments.findIndex(
                                    ({ uuid }) => uuid === comment.uuid
                                );
                                return update(notes, {
                                    list: {
                                        [noteIndex]: {
                                            comments: {
                                                [commentIndex]: {
                                                    assets: {
                                                        $push: [asset]
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    }
                });
            }
        }
    };
}
const _attachmentUploadErrorHandlerFactory = (dispatch) => (e) => {
    let message;
    if (e.code === 413) {
        message = (
            <>
                {__('ERR_FILE_SIZE', { size: bytesToSize(e.data.limit) })}
                {e.data.status === 'ANONYMOUS' && (
                    <>
                        <br />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: __('ACCOUNT_CREATE_TO_INCREASE_LIMIT', {
                                    url: signupLink()
                                })
                            }}
                        />
                    </>
                )}
            </>
        );
    }

    if (e.code === 429) message = __('ERR_TOO_MANY_UPLOADS');
    if (message)
        dispatch(
            openFeedbackModal(message, undefined, { title: __('ERR_MODAL_TITLE'), isError: true })
        );
};
export function useCreateNotesAndComments() {
    const setNotes = useSetAtom(notesAtom);
    const dispatch = useDispatch();
    const { fileUuid: currentFileUuid } = useSelector(fileUuidSelector);

    const noteCreate = useCallback(
        async (
            {
                assignees,
                attachments,
                content,
                draft,
                estimatedEndDate,
                fileUuid,
                fileVersion,
                folderUuid,
                isTask,
                notify,
                teamUuid,
                tags,
                type,
                metadata,
                imageData,
                postedAs,
                recaptcha
            }: NoteCreateBody & { attachments: File[]; folderUuid: string },
            isCastFile?: boolean,
            castFileUuid?: string,
            password?: string
        ) => {
            const note = await _createNote(
                {
                    assignees,
                    attachmentsNumber: attachments.length,
                    content,
                    draft,
                    estimatedEndDate,
                    fileUuid,
                    fileVersion,
                    castFileUuid,
                    isTask,
                    notify,
                    teamUuid,
                    tags,
                    type,
                    metadata,
                    imageData,
                    postedAs,
                    recaptcha
                },
                isCastFile,
                password
            );
            if (attachments.length) {
                const lastComment = note.comments[note.comments.length - 1];
                uploadAttachments({
                    commentUuid: lastComment.uuid,
                    noteUuid: note.uuid,
                    attachments,
                    castFileUuid
                }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
            }

            if (currentFileUuid !== fileUuid) {
                return;
            }

            setNotes((notes) => {
                const _note = {
                    ...note,
                    task: note.task && sqlDatesToJsDates(note.task),
                    file_version: fileVersion,
                    created: new Date(note.created),
                    metadata: isJsonString(note.metadata)
                        ? JSON.parse(note.metadata)
                        : note.metadata,
                    user: note.user,
                    comments: note.comments.map((c) => ({
                        ...c,
                        created: new Date(note.created),
                        user: note.user,
                        note_uuid: note.uuid,
                        user_profile: {
                            firstname: note.user.firstname,
                            lastname: note.user.lastname
                        }
                    }))
                };
                const noteIndex = notes.findIndex(
                    (n) =>
                        n.uuid === note.uuid ||
                        (n.uuid === 'new' && n.comments[0].content === note.comments[0].content) ||
                        (n.type === 'global' && note.type === 'global')
                );
                if (noteIndex > -1)
                    return update(notes, {
                        [noteIndex]: {
                            $merge: _note
                        }
                    });
                return update(notes, {
                    $unshift: [_note]
                });
            });
        },
        [currentFileUuid]
    );
    return {
        noteCreate,

        noteDelete: async (uuid: string) => {
            setNotes((notes) => {
                return notes.filter(({ uuid: noteUuid }) => noteUuid !== uuid);
            });
            await deleteNote(uuid);
        },
        commentCreate: async (
            noteUuid: string,
            {
                attachments,
                content,
                isDraft,
                fileUuid,
                fileVersion,
                folderUuid,
                castFileUuid,
                shouldNotify,
                keywords,
                postedAs
            }: {
                attachments: File[];
                content: string;
                isDraft?: boolean;
                fileUuid: string;
                fileVersion: number;
                folderUuid: string;
                castFileUuid?: string;
                shouldNotify?: boolean;
                keywords?: string[];
                postedAs?: string;
            },
            password?: string
        ) => {
            const comment = await createComment(
                noteUuid,
                {
                    attachmentsNumber: attachments?.length,
                    content,
                    draft: isDraft,
                    fileUuid,
                    fileVersion,
                    folderUuid,
                    castFileUuid,
                    notify: !!shouldNotify,
                    tags: keywords,
                    postedAs
                },
                password
            );
            if (attachments.length) {
                uploadAttachments({
                    commentUuid: comment.uuid,
                    noteUuid,
                    attachments,
                    castFileUuid
                }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
            }

            setNotes((notes) => {
                const noteIndex = notes.findIndex(({ uuid }) => uuid === noteUuid);
                if (noteIndex === -1) return notes;
                const commentIndex = notes[noteIndex].comments.findIndex(
                    (c) => c.uuid === comment.uuid
                );
                if (commentIndex > -1) return notes;
                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            $unshift: [
                                {
                                    ...comment,
                                    created: new Date(comment.created)
                                }
                            ]
                        }
                    }
                });
            });
        },

        commentEdit: async (
            noteUuid: string,
            commentUuid: string,
            {
                attachments,
                content,
                isDraft,
                tags
            }: {
                attachments?: File[];
                content: string;
                isDraft?: boolean;
                tags: string[];
            }
        ) => {
            const r = await _editComment(noteUuid, commentUuid, {
                attachmentsNumber: attachments?.length,
                content,
                draft: isDraft,
                tags
            });

            if (attachments && attachments.length > 0) {
                await uploadAttachments({
                    commentUuid: r.comment.uuid,
                    noteUuid: noteUuid,
                    attachments: attachments
                }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
            }
            setNotes((notes) => {
                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                if (noteIndex === -1) return notes;
                const commentIndex = notes[noteIndex].comments.findIndex(
                    (c) => c.uuid === commentUuid
                );
                if (commentIndex === -1) return notes;
                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            [commentIndex]: {
                                $merge: {
                                    ...r.comment,
                                    updated: new Date()
                                }
                            }
                        }
                    }
                });
            });
        },

        commentDelete: async (noteUuid: string, commentUuid: string) => {
            setNotes((notes) => {
                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                if (noteIndex === -1) return notes;
                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            $set: notes[noteIndex].comments.filter((c) => c.uuid !== commentUuid)
                        }
                    }
                });
            });
            await deleteComment({ uuid: commentUuid, noteUuid });
        }
    };
}
