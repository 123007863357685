import './FormComment.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TEAM_ADMIN } from '@he-novation/config/constants/projects.constants';
import {
    AreaSelectionState,
    AreaSelectionType
} from '@he-novation/config/types/area-selection.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { NoteCreateBody } from '@he-novation/config/types/payloads/note.payload';
import { Project } from '@he-novation/config/types/project.types';
import { TeamWithMembers } from '@he-novation/config/types/team.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Error } from '@he-novation/design-system/components/text/Error/Error';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import { verifyEmailOrPassword } from '@he-novation/front-shared/async/transfer.async';
import { asyncUserLogin } from '@he-novation/front-shared/async/user.async';
import { dropFrames, msTimeCodeToSeconds, timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { useAtomValue } from 'jotai';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { audioAtom } from '../../../atoms/file-atoms/audio-atom';
import { activeSubtitlesAtom } from '../../../atoms/file-atoms/subtitle-atoms';
import { videoAtom } from '../../../atoms/file-atoms/video-atom';
import { selectedNoteAtom } from '../../../atoms/note-atoms';
import { useAudioControls } from '../../../hooks/useAudioControls';
import { useGenerateThumbnail } from '../../../hooks/useGenerateThumbnail';
import { useCreateNotesAndComments } from '../../../hooks/useNotes';
import { useOnKeyDown } from '../../../hooks/useOnKeyDown';
import { useVideoControls } from '../../../hooks/useVideoControls';
import TimeCodes from '../../form/fields/TimeCodes/TimeCodes';
import FormCommentActions from './FormCommentActions/FormCommentActions';
import FormCommentType from './FormCommentType/FormCommentType';

import { FormCommentTimeCode } from '$components/form/FormComment/FormCommentTimeCode';
import { TYPE_NOTE } from '$constants/constants.note';
import mapPayloadToMetadata from '$redux/content/note/map/mapPayloadToMetadata';

function getType(
    areaSelection: AreaSelectionState,
    fileType: FileEnum,
    iframeCapture: any,
    shapeType?: string
): 'global' | 'sequence' | 'rectangle' | 'timecode' | 'page' | 'areaselection' {
    let type = shapeType;
    if (!type) {
        type = areaSelection.selection.length ? TYPE_NOTE.DRAW : TYPE_NOTE.RECTANGLE;
        if (fileType === 'office' || (!iframeCapture && fileType === 'html'))
            type = TYPE_NOTE.GLOBAL;
    }
    return type as 'global' | 'sequence' | 'rectangle' | 'timecode' | 'page' | 'areaselection';
}

type FormCommentProps = {
    shapeType: string;
    areaSelection: AreaSelectionState;
    areaSelectionStartShape: (type: AreaSelectionType) => void;
    areaSelectionToggle: () => void;
    fileType: FileEnum;
    iframeCapture: any;
    isPublicFile?: boolean;
    onTypeChange: (type: string) => void;
    associatedProject?: Project;
    onKeyD: (cb: (e: KeyboardEvent) => void) => void;
    onKeyEnter: (cb: (e: KeyboardEvent) => void) => void;
    mediaControls: any;
    close?: () => void;
    shapeIsFinished?: boolean;
    mediaData: any;
    selectedTeam?: string;
    isTask?: boolean;
    toggleKeyboardListeners: (enable: boolean) => void;
    fetchAccountAndUser: () => Promise<void>;
    fileUuid: string;
    fileHighlightedVersion: number;
    fileVersion: number;
    folderUuid: string;
    routeParams: {
        castFileUuid?: string;
    };
    castFileUuid?: string;
    height: number;
    width: number;
    page: number;
    commentUuid?: string;
    currentUser?: {
        uuid: string;
    };
    teams?: TeamWithMembers[];
    openAttachmentsModal: () => void;
    openKeywordsModal: () => void;
    password?: string;
    activeAudioTrack?: string;
    onSubmit?: () => void;
};

export function FormComment({
    commentUuid,
    areaSelection,
    areaSelectionToggle,
    fileType,
    iframeCapture,
    shapeType,
    onTypeChange,
    isPublicFile,
    currentUser,
    toggleKeyboardListeners,
    teams,
    selectedTeam,
    openKeywordsModal,
    openAttachmentsModal,
    isTask,
    fileUuid,
    fileHighlightedVersion,
    folderUuid,
    routeParams,
    password,
    activeAudioTrack,
    height,
    width,
    page,
    areaSelectionStartShape,
    shapeIsFinished,
    fetchAccountAndUser,
    castFileUuid,
    close,
    onSubmit: _onSubmit
}: FormCommentProps) {
    const formId = 'form-comment';
    const isDraft = useRef(false);
    const [type, setType] = useState<
        'global' | 'sequence' | 'rectangle' | 'timecode' | 'page' | 'areaselection'
    >(getType(areaSelection, fileType, iframeCapture, shapeType));
    const selectedNote = useAtomValue(selectedNoteAtom);
    const videoState = useAtomValue(videoAtom);
    const audioState = useAtomValue(audioAtom);
    const audioControls = useAudioControls();
    const videoControls = useVideoControls();
    const [tcIn, setTcIn] = useState<string>();
    const [tcOut, setTcOut] = useState<string>();
    const [emailExists, setEmailExists] = useState<boolean>();
    const [postedAs, setPostedAs] = useState<string>();
    const [passwordError, setPasswordError] = useState<boolean>();
    const [attachments, setAttachments] = useState<File[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [shouldNotify, setShouldNotify] = useState<boolean>(false);
    const [commentValue, setCommentValue] = useState<string>();
    const recaptcha = useRef<ReCaptchaInstance>();
    const textareaComment = useRef<any>(null);
    const { noteCreate, commentCreate, commentEdit } = useCreateNotesAndComments();

    const comment = selectedNote?.comments.find(({ uuid }) => uuid === commentUuid);

    useEffect(() => {
        onTypeChange?.(type);
    }, [type]);

    const generateThumbnail = useGenerateThumbnail(isPublicFile);
    const activeSubtitles = useAtomValue(activeSubtitlesAtom);

    const onSubmit = useCallback(
        async (
            e,
            {
                type,
                content,
                shouldNotify,
                postedAs,
                password: userPassword,
                estimatedEndDate,
                assignees
            }: {
                type: 'global' | 'sequence' | 'rectangle' | 'timecode' | 'page' | 'areaselection';
                content: string;
                isImportant?: boolean;
                shouldNotify?: boolean;
                postedAs?: string;
                password?: string;
                estimatedEndDate?: Date;
                assignees?: string[];
            }
        ) => {
            if (e) e.preventDefault();

            if (postedAs && userPassword) {
                try {
                    setPasswordError(false);
                    await asyncUserLogin(postedAs, userPassword);
                    await fetchAccountAndUser();
                    postedAs = undefined;
                } catch (e) {
                    setPasswordError(true);
                    return;
                }
            }

            const payload: NoteCreateBody & { attachments: File[]; folderUuid: string } = {
                type,
                content,
                notify: !!shouldNotify,
                assignees: assignees || undefined,
                estimatedEndDate: estimatedEndDate,
                attachments: attachments,
                tags: tags,
                draft: isDraft.current,
                fileUuid: fileUuid,
                fileVersion: fileHighlightedVersion,
                folderUuid: folderUuid,
                castFileUuid: routeParams.castFileUuid,
                isTask,
                postedAs
            };

            if (isPublicFile) {
                payload.recaptcha = await recaptcha.current!.execute('createCastNote');
            }

            let _tcIn, _tcOut, _frame;
            let _areaSelection: any = {};

            if (selectedNote) {
                if (comment) {
                    commentEdit(selectedNote!.uuid, comment.uuid, {
                        attachments: attachments,
                        content: content,
                        tags: tags,
                        isDraft: isDraft.current
                    });
                } else {
                    commentCreate(selectedNote.uuid, payload, password);
                }
                _onSubmit?.();
            } else {
                if (fileType === FileEnum.Video) {
                    if (type === TYPE_NOTE.DRAW || type === TYPE_NOTE.RECTANGLE) {
                        _tcIn = videoState.timecodeWithTimecodeStart.replace(/[.;]/, ':');
                        _frame = videoState.frame;
                    }
                    if (type === TYPE_NOTE.SEQUENCE) {
                        _tcIn = tcIn;
                        _tcOut = tcOut;
                    }
                }

                if (fileType === FileEnum.Audio) {
                    if (type === TYPE_NOTE.DRAW || type === TYPE_NOTE.RECTANGLE) {
                        _tcIn = audioState.timecode;
                    }
                    if (type === TYPE_NOTE.SEQUENCE) {
                        _tcIn = tcIn;
                        _tcOut = tcOut;
                    }
                }

                if (type === TYPE_NOTE.RECTANGLE || type === TYPE_NOTE.DRAW) {
                    payload.type = 'areaselection';
                    _areaSelection = areaSelection;
                }

                payload.metadata = mapPayloadToMetadata({
                    activeAudioTrack: activeAudioTrack,
                    activeSubtitles: activeSubtitles,
                    tcIn: _tcIn,
                    tcOut: _tcOut,
                    areaSelection: _areaSelection,
                    height: height,
                    width: width,
                    page: page,
                    frame: _frame
                });

                if (selectedTeam) payload.teamUuid = selectedTeam;

                if (type != 'global') {
                    payload.imageData = await generateThumbnail(payload.metadata);
                }

                noteCreate(payload, isPublicFile, castFileUuid, password);
                _onSubmit?.();
            }

            if (typeof close === 'function') close();
        },
        [generateThumbnail, videoState, activeSubtitles, height, width]
    );

    const verifyEmail = useCallback(async (email: string) => {
        try {
            if (!recaptcha.current) throw 'Recaptcha not loaded';
            await verifyEmailOrPassword(
                await recaptcha.current.execute('verifyEmailOrPassword'),
                email
            );
            setEmailExists(true);
        } catch (e) {
            setEmailExists(false);
        }
    }, []);

    useEffect(() => {
        if (isPublicFile) {
            load(process.env.RECAPTCHA_FRONT_KEY!).then((r) => {
                recaptcha.current = r;

                if (postedAs) verifyEmail(postedAs);
            });
        }

        // Need wait setSize from AreaSelection.jsx
        if (!areaSelection.toggled) {
            setTimeout(
                () =>
                    onTypeChange(
                        areaSelection.selection.length ? TYPE_NOTE.DRAW : TYPE_NOTE.RECTANGLE
                    ),
                500
            );
        } else {
            if (!areaSelection.selection.length)
                areaSelectionStartShape(AreaSelectionType.Rectangle);
        }

        let timeout: ReturnType<typeof setTimeout>;
        if (textareaComment.current?.el) {
            // Need to wait the end of the open panel animation (.3s)
            timeout = setTimeout(() => textareaComment.current.el.current.focus(), 400);
        }

        if (fileType === FileEnum.Video) {
            videoControls.pause();
        }

        if (fileType === FileEnum.Audio) {
            audioControls.pause();
        }

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (shapeIsFinished) {
            textareaComment.current?.el.current.focus();
        }
    }, [shapeIsFinished]);

    useEffect(() => {
        if (type === TYPE_NOTE.SEQUENCE) {
            if (fileType === FileEnum.Video)
                setTcIn(videoState.timecodeWithTimecodeStart.replace(/[.;]/, ':'));
            if (fileType === FileEnum.Audio) setTcIn(audioState.timecode);
        }
    }, [type]);

    useOnKeyDown(
        'enter',
        (e) => {
            if (!e.shiftKey) return;
            onSubmit(null, {
                type,
                content: commentValue!,
                isImportant: false,
                shouldNotify
            });
            toggleKeyboardListeners(true);
        },
        [commentValue],
        {
            useKeyboardListenersSelector: false
        }
    );

    useEffect(() => {
        if (fileType === FileEnum.Audio && type === TYPE_NOTE.SEQUENCE && tcIn && tcOut) {
            audioControls.setTimecodeRange([tcIn, tcOut]);
        }
        if (type !== TYPE_NOTE.SEQUENCE && audioState.selectedRange) {
            audioControls.setSelectedRange(null);
        }
    }, [type, tcIn, tcOut]);

    return (
        <ModularForm
            id={formId}
            className={`c-form-comment ${isTask ? 'c-task-form' : ''}`}
            onSubmit={onSubmit}
        >
            {!selectedNote && (
                <FormCommentType
                    formId={formId}
                    type={type}
                    areaSelection={areaSelection}
                    areaSelectionToggle={areaSelectionToggle}
                    fileType={fileType}
                    iframeCapture={iframeCapture}
                    onTypeChange={(e) =>
                        setType(
                            e.currentTarget.value as
                                | 'global'
                                | 'sequence'
                                | 'rectangle'
                                | 'timecode'
                                | 'page'
                                | 'areaselection'
                        )
                    }
                />
            )}

            {!selectedNote && <FormCommentTimeCode fileType={fileType} />}

            {type === TYPE_NOTE.SEQUENCE && (
                <TimeCodes
                    formId={formId}
                    tcIn={tcIn}
                    tcOut={tcOut}
                    setTcIn={setTcIn}
                    setTcOut={setTcOut}
                    timecode={
                        fileType === FileEnum.Video
                            ? videoState.timecodeWithTimecodeStart
                            : audioState.timecode
                    }
                    mask={
                        fileType === FileEnum.Video
                            ? `__:__:__${dropFrames(videoState.frameRate) ? ';' : ':'}__`
                            : '__:__:__.___'
                    }
                    timeCodeToSeconds={
                        fileType === FileEnum.Video
                            ? (value: string) => timeCodeToSeconds(value, videoState.frameRate)
                            : msTimeCodeToSeconds
                    }
                />
            )}

            {isPublicFile && !currentUser?.uuid && (
                <>
                    <FormField
                        formId={formId}
                        name="postedAs"
                        theme={Theme.Dark}
                        type={'email'}
                        label={__('EMAIL')}
                        value={postedAs}
                        disabled={emailExists}
                        validation={{
                            required: true
                        }}
                        onFocus={() => toggleKeyboardListeners(false)}
                        onBlur={async (e) => {
                            const value = (e.currentTarget as HTMLInputElement).value;
                            toggleKeyboardListeners(true);
                            localStorage.setItem('castEmail', value);
                            await this.verifyEmail(value);
                        }}
                        onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                await verifyEmail(e.currentTarget.value);
                            }
                        }}
                        onChange={(e) => setPostedAs(e.target.value)}
                    >
                        {emailExists ? (
                            <Button
                                className="email-reset"
                                icon="cross"
                                tone={ButtonTone.Alert}
                                type="button"
                                onClick={() => {
                                    setEmailExists(false);
                                    setPostedAs('');
                                }}
                            />
                        ) : null}
                    </FormField>
                    {emailExists && (
                        <FormField
                            label={__('PASSWORD')}
                            formId={formId}
                            type={'password'}
                            onFocus={() => toggleKeyboardListeners(false)}
                            onBlur={() => toggleKeyboardListeners(true)}
                            name="password"
                            autoFocus
                        />
                    )}

                    {passwordError && <Error error={__('ERROR_PASSWORD')} />}
                </>
            )}

            <FormField
                type={'textarea'}
                name="content"
                theme={Theme.Dark}
                value={comment ? comment.content : ''}
                formId={formId}
                componentRef={textareaComment}
                validation={{
                    required: true,
                    validateOnBlur: false
                }}
                onChange={(e) => setCommentValue(e.target.value)}
                onFocus={() => toggleKeyboardListeners(false)}
                onBlur={() => toggleKeyboardListeners(true)}
                autoFocus
            />
            <FormCommentActions
                formId={formId}
                isPublicFile={isPublicFile}
                changeNotify={() => setShouldNotify(!shouldNotify)}
                isTask={isTask}
                setAttachments={setAttachments}
                attachments={attachments}
                setKeywords={setTags}
                keywords={tags}
                openKeywordsModal={openKeywordsModal}
                openAttachmentsModal={openAttachmentsModal}
                teamMembers={
                    teams && selectedTeam
                        ? teams
                              .filter(
                                  ({ uuid, name }) => uuid === selectedTeam || name === TEAM_ADMIN
                              )
                              .reduce((acc, curr) => [...acc, ...curr.members], [])
                        : null
                }
            />

            <div className="form-comment-buttons">
                {!isPublicFile && (
                    <Button
                        tone={ButtonTone.Outlined}
                        id="comment-draft-submit"
                        onClick={() => {
                            isDraft.current = true;
                        }}
                    >
                        {__('REACT_SAVE_DRAFT_SHORT')}
                    </Button>
                )}
                <Button
                    tone={ButtonTone.Primary}
                    onClick={() => {
                        isDraft.current = false;
                    }}
                >
                    {__('REACT_PUBLISH')}
                </Button>
            </div>
        </ModularForm>
    );
}
