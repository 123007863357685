import React from 'react';

const ModalAttachment = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-attachment" */ './modals/ModalAttachment/ModalAttachmentContainer'
    )
);
const ModalCardUpdate = React.lazy(() =>
    import(/* webpackChunkName: "modal-card-update" */ './modals/ModalCardUpdate/ModalCardUpdate')
);
const ModalFormCast = React.lazy(() =>
    import(/* webpackChunkName: "modal-form-cast" */ './modals/ModalFormCast/ModalFormCast')
);
const ModalCast = React.lazy(() =>
    import(/* webpackChunkName: "modal-cast" */ './modals/ModalCast/ModalCastContainer')
);
const ModalCastShare = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-cast-share" */ './modals/ModalCastShare/ModalCastShareContainer'
    )
);
const ModalCastFolderTrigger = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-cast-folder-trigger" */ './modals/ModalCastFolderTrigger/ModalCastFolderTrigger'
    )
);
const ModalClientDomain = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-client-domain" */ './modals/ModalClientDomain/ModalClientDomain'
    )
);
const ModalCompany = React.lazy(() =>
    import(/* webpackChunkName: "modal-company" */ './modals/ModalCompany/ModalCompany')
);
const ModalConfirm = React.lazy(() =>
    import(/* webpackChunkName: "modal-confirm" */ './modals/ModalConfirm/ModalConfirm')
);
const ModalConfirmDelete = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-confirm-delete" */ './modals/ModalConfirmDelete/ModalConfirmDelete'
    )
);
const ModalContactAdmin = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-contact-admin" */ './modals/ModalContactAdmin/ModalContactAdminContainer'
    )
);
const ModalContactForm = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-contact-form" */ './modals/ModalContactForm/ModalContactForm'
    )
);
const ModalDate = React.lazy(() =>
    import(/* webpackChunkName: "modal-date" */ './modals/ModalDate/ModalDateContainer')
);
const ModalDeleteFile = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-delete-file" */ './modals/ModalDeleteFile/ModalDeleteFileContainer'
    )
);
const ModalDeleteTeam = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-delete-team" */ './modals/ModalDeleteTeam/ModalDeleteTeamContainer'
    )
);
const ModalError = React.lazy(() =>
    import(/* webpackChunkName: "modal-error" */ './modals/ModalError/ModalErrorContainer')
);
const ModalEvent = React.lazy(() =>
    import(/* webpackChunkName: "modal-event" */ './modals/ModalEvent/ModalEvent')
);
const ModalFeedback = React.lazy(() =>
    import(/* webpackChunkName: "modal-feedback" */ './modals/ModalFeedback/ModalFeedbackContainer')
);
const ModalFileAdd = React.lazy(() =>
    import(/* webpackChunkName: "modal-file-add" */ './modals/ModalFileAdd')
);
const ModalFileInfo = React.lazy(() =>
    import(/* webpackChunkName: "modal-file-info" */ './modals/ModalFileInfo/ModalFileInfo')
);
const ModalFileVersions = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-file-versions" */ './modals/ModalFileVersions/ModalFileVersions'
    )
);
const ModalFileVersionsComparator = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-file-versions-comparator" */ './modals/ModalFileVersions/ModalFileVersionsComparatorContainer'
    )
);
const ModalFolderAdd = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-folder-add" */ './modals/ModalFolderAdd/ModalFolderAddContainer'
    )
);
const ModalFolderDownload = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-folder-download" */ './modals/ModalFolderDownload/ModalFolderDownloadContainer'
    )
);

const ModalFolderShare = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-folder-share" */ './modals/ModalFolderShare/ModalFolderShareContainer'
    )
);
const ModalFolderTeams = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-folder-teams" */ './modals/ModalFolderTeams/ModalFolderTeams'
    )
);
const ModalFormClientLabel = React.lazy(() =>
    import(/* webpackChunkName: "modal-form-clientlabel" */ './modals/ModalFormClientLabel')
);
const ModalFormClientUser = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-folder-client-user" */ '$components/modal/modals/ModalFormClientUser/ModalFormClientUser'
    )
);
const ModalFormClientUserPassword = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-form-client-user-password" */ './modals/ModalFormClientUserPassword'
    )
);
const ModalProfilePicture = React.lazy(() =>
    import(/* webpackChunkName: "modal-profile-picture" */ './modals/ModalProfilePicture')
);
const ModalFormProject = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-form-project" */ './modals/ModalFormProject/ModalFormProject'
    )
);

const ModalGoogleCalendars = React.lazy(() =>
    import(
        /*webpackChunkName: "modal-google-calendars*/ './modals/ModalGoogleCalendars/ModalGoogleCalendars'
    )
);

const ModalGroupForm = React.lazy(() =>
    import(/* webpackChunkName: "modal-group-form" */ './modals/ModalGroupForm/ModalGroupForm')
);
const ModalImportContact = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-import-contact" */ './modals/ModalImportContact/ModalImportContact'
    )
);
const ModalImportContactError = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-import-contact-error" */ './modals/ModalImportContactError/ModalImportContactError'
    )
);
const ModalItem = React.lazy(() =>
    import(/* webpackChunkName: "modal-item" */ './modals/ModalItem/ModalItem')
);
const ModalItemBookingStats = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-item-booking-stats" */ './modals/ModalItemBookingStats/ModalItemBookingStats'
    )
);
const ModalItemKit = React.lazy(() =>
    import(/* webpackChunkName: "modal-item-kit" */ './modals/ModalItemKit/ModalItemKit')
);
const ModalKeyWord = React.lazy(() =>
    import(/* webpackChunkName: "modal-key-word" */ './modals/ModalKeyword/ModalKeyWordContainer')
);
const ModalOutlookCalendars = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-outlook-calendars" */ './modals/ModalOutlookCalendars/ModalOutlookCalendars'
    )
);
const ModalPassword = React.lazy(() =>
    import(/* webpackChunkName: "modal-password" */ './modals/ModalPassword/ModalPassword')
);
const ModalPasswordUpdate = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-password-update" */ './modals/ModalPasswordUpdate/ModalPasswordUpdate'
    )
);

const ModalPluginConfiguration = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-plugin-configuration" */ './modals/ModalPluginConfiguration/ModalPluginConfiguration'
    )
);
const ModalProjectMember = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-project-member" */ './modals/ModalProjectMember/ModalProjectMemberContainer'
    )
);
const ModalProjectTeams = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-project-teams" */ './modals/ModalProjectTeams/ModalProjectTeamsContainer'
    )
);
const ModalPublicFile = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-plugin-public-file" */ './modals/ModalPublicFile/ModalPublicFileContainer'
    )
);
const ModalRenameFile = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-rename-file" */ './modals/ModalRenameFile/ModalRenameFileContainer'
    )
);
const ModalRenameFolder = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-rename-folder" */ './modals/ModalRenameFolder/ModalRenameFolderContainer'
    )
);
const ModalRenameTeam = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-rename-team" */ './modals/ModalRenameTeam/ModalRenameTeamContainer'
    )
);
const ModalReviewersList = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-reviewers-list" */ './modals/ModalReviewersList/ModalReviewersListContainer'
    )
);
const ModalShowSharedFolders = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-shared-folders" */ './modals/ModalShowSharedFolders/ModalShowSharedFolders'
    )
);
const ModalSubtitlesUpload = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-subtitles-upload" */ './modals/ModalSubtitlesUpload/ModalSubtitlesUpload'
    )
);
const ModalSubtitlesBurn = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-subtitles-burn" */ './modals/ModalSubtitlesBurn/ModalSubtitlesBurn'
    )
);
const ModalSubtitlesCreate = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-subtitles-create" */ './modals/ModalSubtitlesCreate/ModalSubtitlesCreate'
    )
);
const ModalSubtitlesGenerate = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-subtitles-generate" */ './modals/ModalSubtitlesGenerate/ModalSubtitlesGenerate'
    )
);
const ModalTaskForm = React.lazy(() =>
    import(/* webpackChunkName: "modal-task-form" */ './modals/ModalTaskForm/ModalTaskForm')
);
const ModalUserProfile = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-user-profile" */ './modals/ModalUserProfileForm/ModalUserProfile'
    )
);
const ModalUserTransfer = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-user-transfer" */ './modals/ModalUserTransfer/ModalUserTransferContainer'
    )
);
const ModalVersionAdd = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-version-add" */ './modals/ModalVersionAdd/ModalVersionAddContainer'
    )
);
const ModalRecurseSettings = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-recurse-settings" */ './modals/ModalRecurseSettings/ModalRecurseSettingsContainer'
    )
);
const ModalViews = React.lazy(() =>
    import(/* webpackChunkName: "modal-views" */ './modals/ModalViews/ModalViewsContainer')
);
const ModalWait = React.lazy(() =>
    import(/* webpackChunkName: "modal-wait" */ './modals/ModalWait/ModalWaitContainer')
);
const ModalFormWording = React.lazy(() =>
    import(/* webpackChunkName: "modal-form-wording" */ './modals/ModalFormWording')
);
const ModalFormWordingList = React.lazy(() =>
    import(/* webpackChunkName: "modal-form-wording-list" */ './modals/ModalFormWordingList')
);
const ModalFormWordingListEntry = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-form-wording-list-entry" */ './modals/ModalFormWordingListEntry'
    )
);
const ModalWordingUpdate = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-wording-update" */ './modals/ModalWordingUpdate/ModalWordingUpdate'
    )
);

const ModalCopyPaste = React.lazy(() =>
    import(/* webpackChunkName: "modal-copy-paste" */ './modals/ModalCopyPaste')
);

const ModalTwoFactorAuthentication = React.lazy(() =>
    import(
        /* webpackChunkName: "modal-two-factor-authentication" */ './modals/ModalTwoFactorAuthentication/ModalTwoFactorAuthentication'
    )
);

import {
    ADD_CONTACT,
    ADD_GROUP,
    ATTACHMENT_NOTE,
    ATTACHMENT,
    CAST,
    CAST_FORM,
    CLIENT_DOMAIN,
    CLIENT,
    CLIENT_USER,
    CLIENT_USER_PASSWORD,
    COMPANY,
    CONTACT_ADMIN,
    DATE,
    DELETE_CONFIRM,
    DELETE_FILE,
    DELETE_TEAM,
    EDIT_CONTACT,
    EDIT_GROUP,
    EVENT,
    FEEDBACK,
    FILE_ADD,
    FILE_INFO,
    FILE_VERSIONS_COMPARATOR,
    FILE_VERSIONS,
    FOLDER_ADD,
    FOLDER_SHARE,
    FOLDER_TEAMS,
    GOOGLE_CALENDARS,
    IMPORT_CONTACT_ERROR,
    IMPORT_CONTACT,
    ITEM_KIT,
    ITEM,
    KEY_WORD_NOTE,
    KEY_WORD,
    OUTLOOK_CALENDARS,
    PASSWORD_FORM,
    PASSWORD_UPDATE_FORM,
    PROJECT_FORM,
    PROJECT_MEMBER,
    PROJECT_TEAMS,
    PUBLIC_FILE,
    RECURSIVE_SETTINGS,
    RENAME_FILE,
    RENAME_FOLDER,
    RENAME_TEAM,
    REVIEWERS_LIST,
    SERVER_ERROR,
    SHOW_SHARED_FOLDERS,
    SUBTITLES_CREATE,
    SUBTITLES,
    TASK_FORM,
    USER_PROFILE_FORM,
    USER_TRANSFER,
    VERSION_ADD,
    VIEWS,
    WAIT,
    PLUGIN_CONFIGURATION,
    CARD_UPDATE,
    CONFIRM,
    FOLDER_DOWNLOAD,
    CAST_FORM_FOLDER,
    CAST_SHARE,
    CAST_FOLDER_TRIGGERS,
    ITEM_BOOKING_STATS,
    FORM_WORDING_LIST,
    FORM_WORDING_LIST_ENTRY,
    FORM_WORDING,
    WORDING_UPDATE,
    CLIENT_LABEL,
    SUBTITLES_GENERATE,
    TWO_FACTOR_AUTHENTICATION,
    SUBTITLES_BURN
} from '@he-novation/paths/modals.constants';
import { ModalFormClient } from './modals/ModalFormClient';
import ModalFormCastFolder from '$components/modal/modals/ModalFormCastFolder';
import { PROFILE_PICTURE } from '@he-novation/paths/modals.constants';
import { COPY_PASTE } from '$components/modal/modals/ModalCopyPaste';

export default {
    [ADD_CONTACT]: ModalContactForm,
    [ADD_GROUP]: ModalGroupForm,
    [ATTACHMENT_NOTE]: ModalAttachment,
    [ATTACHMENT]: ModalAttachment,
    [CARD_UPDATE]: ModalCardUpdate,
    [CAST]: ModalCast,
    [CAST_FOLDER_TRIGGERS]: ModalCastFolderTrigger,
    [CAST_FORM]: ModalFormCast,
    [CAST_FORM_FOLDER]: ModalFormCastFolder,
    [CAST_SHARE]: ModalCastShare,
    [CLIENT]: ModalFormClient,
    [CLIENT_DOMAIN]: ModalClientDomain,
    [CLIENT_LABEL]: ModalFormClientLabel,
    [CLIENT_USER]: ModalFormClientUser,
    [CLIENT_USER_PASSWORD]: ModalFormClientUserPassword,
    [CONFIRM]: ModalConfirm,
    [COMPANY]: ModalCompany,
    [CONTACT_ADMIN]: ModalContactAdmin,
    [COPY_PASTE]: ModalCopyPaste,
    [PROFILE_PICTURE]: ModalProfilePicture,
    [DATE]: ModalDate,
    [DELETE_CONFIRM]: ModalConfirmDelete,
    [DELETE_FILE]: ModalDeleteFile,
    [DELETE_TEAM]: ModalDeleteTeam,
    [EDIT_CONTACT]: ModalContactForm,
    [EDIT_GROUP]: ModalGroupForm,
    [EVENT]: ModalEvent,
    [FEEDBACK]: ModalFeedback,
    [FILE_ADD]: ModalFileAdd,
    [FILE_INFO]: ModalFileInfo,
    [FILE_VERSIONS_COMPARATOR]: ModalFileVersionsComparator,
    [FILE_VERSIONS]: ModalFileVersions,
    [FOLDER_ADD]: ModalFolderAdd,
    [FOLDER_DOWNLOAD]: ModalFolderDownload,
    [FOLDER_SHARE]: ModalFolderShare,
    [FOLDER_TEAMS]: ModalFolderTeams,
    [GOOGLE_CALENDARS]: ModalGoogleCalendars,
    [IMPORT_CONTACT_ERROR]: ModalImportContactError,
    [IMPORT_CONTACT]: ModalImportContact,
    [ITEM_KIT]: ModalItemKit,
    [ITEM]: ModalItem,
    [ITEM_BOOKING_STATS]: ModalItemBookingStats,
    [KEY_WORD_NOTE]: ModalKeyWord,
    [KEY_WORD]: ModalKeyWord,
    [OUTLOOK_CALENDARS]: ModalOutlookCalendars,
    [PASSWORD_FORM]: ModalPassword,
    [PASSWORD_UPDATE_FORM]: ModalPasswordUpdate,
    [PLUGIN_CONFIGURATION]: ModalPluginConfiguration,
    [PROJECT_FORM]: ModalFormProject,
    [PROJECT_MEMBER]: ModalProjectMember,
    [PROJECT_TEAMS]: ModalProjectTeams,
    [PUBLIC_FILE]: ModalPublicFile,
    [RENAME_FILE]: ModalRenameFile,
    [RENAME_FOLDER]: ModalRenameFolder,
    [RENAME_TEAM]: ModalRenameTeam,
    [RECURSIVE_SETTINGS]: ModalRecurseSettings,
    [REVIEWERS_LIST]: ModalReviewersList,
    [SERVER_ERROR]: ModalError,
    [SHOW_SHARED_FOLDERS]: ModalShowSharedFolders,
    [SUBTITLES_BURN]: ModalSubtitlesBurn,
    [SUBTITLES_CREATE]: ModalSubtitlesCreate,
    [SUBTITLES_GENERATE]: ModalSubtitlesGenerate,
    [SUBTITLES]: ModalSubtitlesUpload,
    [TASK_FORM]: ModalTaskForm,
    [TWO_FACTOR_AUTHENTICATION]: ModalTwoFactorAuthentication,
    [USER_PROFILE_FORM]: ModalUserProfile,
    [USER_TRANSFER]: ModalUserTransfer,
    [VERSION_ADD]: ModalVersionAdd,
    [VIEWS]: ModalViews,
    [WAIT]: ModalWait,
    [FORM_WORDING]: ModalFormWording,
    [FORM_WORDING_LIST]: ModalFormWordingList,
    [FORM_WORDING_LIST_ENTRY]: ModalFormWordingListEntry,
    [WORDING_UPDATE]: ModalWordingUpdate
};
