import type { FileFull, VersionAssetRecap } from '@he-novation/config/types/file.types';
import { FileVotes } from '@he-novation/config/types/file.types';
import type {
    FileCopyBody,
    FileCreateBody,
    FileFinalVoteBody,
    FileMoveParams,
    FileSpecificParams,
    FileStatusUpdateBody,
    FileUpdateBody
} from '@he-novation/config/types/payloads/file.payload';
import {
    PluginExportBody,
    PluginExportParams
} from '@he-novation/config/types/payloads/plugin.payload';
import { PluginExportFile } from '@he-novation/config/types/plugin.types';
import { FileCreateResponse } from '@he-novation/config/types/responses/file.responses';
import type { Subtitle } from '@he-novation/config/types/subtitle.types';
import {
    filePaths,
    pathWithParams,
    pluginPaths,
    publicApiV1Paths
} from '@he-novation/paths/herawApiPaths';
import { officeLink } from '@he-novation/paths/herawFrontUris';
import { v4 as uuidV4 } from 'uuid';
import { mapFetchAssetsToUpdate } from '../mappers/mapFetchAssetsToUpdate';
import { apiFetch } from './apiFetch';
import { getSignedUrl } from './asset.async';

export const fetchFile = ({
    uuid,
    version,
    public_password,
    isFromCast
}: {
    uuid: string;
    version: number;
    public_password?: string | null;
    isFromCast?: boolean;
}): Promise<FileFull> =>
    apiFetch(
        pathWithParams(
            filePaths.versionSpecific,
            { fileUuid: uuid, fileVersion: version },
            public_password || isFromCast
                ? { p: public_password, isFromCast: isFromCast ? '1' : '0' }
                : undefined
        ),
        {
            method: 'GET'
        }
    ).then((file) => ({
        ...file,
        created: file.created ? new Date(file.created) : undefined,
        updated: file.updated ? new Date(file.updated) : undefined
    }));

export const asyncFileVersionsFetch = (fileUuid: string): Promise<VersionAssetRecap[]> =>
    apiFetch(filePaths.versions, {
        method: 'GET',
        params: {
            fileUuid
        }
    });

export const fileLinkFetch = ({
    uuid,
    version,
    quality,
    password
}: {
    uuid: string;
    version: number;
    quality: string;
    password?: string | null;
}) =>
    apiFetch(filePaths.assetQuality, {
        method: 'GET',
        params: {
            fileUuid: uuid,
            fileVersion: version,
            quality
        },
        query: { p: password }
    }).then(({ url }) => url);

export function download(url: string, name?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name || (url.split('/').pop() as string);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const fileDownload = async (
    uuid: string,
    version: number,
    quality: string,
    password?: string | null,
    name?: string
) => {
    const link = await fileLinkFetch({ uuid, version, quality, password });
    download(link, name);
};

export const fetchAccessToken = (request_token) =>
    apiFetch('/proxy/get/access/token', {
        method: 'POST',
        body: JSON.stringify({ request_token })
    });

export const fetchComparison = async ({
    name,
    type,
    uuid,
    version
}: {
    name: string;
    type: string;
    uuid: string;
    version: number;
}) => {
    const comparisonFile = await fetchFile({ uuid, version });
    const assets = comparisonFile.assets;

    if (type !== 'office') {
        const encryption =
            assets &&
            assets.find(
                ({ metadata }) =>
                    metadata &&
                    'encryption' in metadata &&
                    metadata.encryption?.algorithm === 'aes-128'
            );
        const comparison: any = mapFetchAssetsToUpdate(type, assets, encryption);
        if (encryption) {
            comparison.encryptionRequestToken = uuidV4();
            comparison.encryptionAccessToken = await fileEncryptedAccessTokenRequest(
                comparison.encryptionRequestToken
            ).then(({ access_token }) => access_token);
        }
        return comparison;
    }

    if (assets?.length > 0) {
        return await getSignedUrl(assets[0].uuid).then((signedUrl) => ({
            version,
            source: officeLink(signedUrl)
        }));
    }
};

export const deleteVersion = ({ uuid, version }) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid: uuid, fileVersion: version }), {
        method: 'DELETE'
    });

export const restoreVersion = ({ uuid, version }) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid: uuid, fileVersion: version }), {
        method: 'PATCH'
    });

export const fetchFileVotes = (fileUuid: string, fileVersion: number): Promise<FileVotes> =>
    apiFetch(filePaths.final, {
        method: 'GET',
        params: { fileUuid, fileVersion }
    }).then((r) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const fetchSubtitles = (
    fileUuid: string,
    fileVersion: number,
    password?: string | null
): Promise<Subtitle[]> =>
    apiFetch(filePaths.subtitles, {
        params: { fileUuid, fileVersion },
        query: { p: password },
        method: 'GET'
    });

export const fileUpdate = (fileUuid: string, fileVersion: number, body: FileUpdateBody) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid, fileVersion }), {
        method: 'PUT',
        body
    });

export const fileEncryptedAccessTokenRequest = (request_token) =>
    apiFetch('/proxy/get/access/token', {
        method: 'POST',
        body: JSON.stringify({
            request_token,
            delay: 0
        })
    });

export const fileCopy = (fileUuid: string, fileVersion: number, folderUuid: string) =>
    apiFetch(filePaths.copy, {
        method: 'POST',
        params: {
            fileUuid,
            fileVersion
        } satisfies FileSpecificParams,
        body: {
            folderUuid
        } satisfies FileCopyBody
    });

export const fileDelete = (fileUuid: string) =>
    apiFetch(filePaths.specific, {
        method: 'DELETE',
        params: { fileUuid }
    });

export const fileMove = (fileUuid: string, folderUuid: string) =>
    apiFetch(filePaths.move, {
        params: { fileUuid, folderUuid } satisfies FileMoveParams,
        method: 'PUT'
    });

export const asyncFileRestore = (fileUuid: string, fileVersion: number) =>
    apiFetch(filePaths.restore, {
        method: 'POST',
        params: { fileUuid, fileVersion }
    });

export const fileVoteFinal = (
    fileUuid: string,
    fileVersion: number,
    body: FileFinalVoteBody
): Promise<FileVotes> =>
    apiFetch(filePaths.final, {
        method: 'PUT',
        params: { fileUuid, fileVersion },
        body
    }).then((r) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const fileStatusUpdate = (
    fileUuid: string,
    fileVersion: number,
    body: FileStatusUpdateBody
) =>
    apiFetch(filePaths.status, {
        method: 'PUT',
        params: { fileUuid, fileVersion },
        body
    });

export const exportFiles = (
    folderUuid: string,
    items: PluginExportFile[],
    plugin: 'brightcove' | 'youtube' | 'vimeo'
) =>
    apiFetch(pluginPaths.export, {
        method: 'POST',
        params: {
            plugin
        } satisfies PluginExportParams,
        body: {
            items,
            folderUuid
        } satisfies PluginExportBody
    });

export const asyncConvertFileToVersion = (
    targetFileUuid: string,
    targetFileVersion: number,
    sourceFileUuid: string
) =>
    apiFetch(filePaths.version, {
        method: 'POST',
        params: { fileUuid: targetFileUuid, fileVersion: targetFileVersion },
        body: {
            fileUuid: sourceFileUuid
        }
    });

export const asyncFileEncode = (fileUuid: string, fileVersion: number) =>
    apiFetch(filePaths.encode, {
        method: 'PUT',
        params: { fileUuid, fileVersion }
    });

export function asyncFileCreate(body: FileCreateBody): Promise<FileCreateResponse> {
    return apiFetch(filePaths.single, {
        method: 'POST',
        body
    });
}

export function asyncVersionCreate(
    fileUuid: string,
    body: FileCreateBody
): Promise<FileCreateResponse> {
    return apiFetch(filePaths.versions, {
        method: 'POST',
        params: { fileUuid },
        body
    });
}

export const asyncOpenApiFileFetch = (apiKey: string, fileUuid: string, fileVersion: number) =>
    apiFetch(publicApiV1Paths.file.specificVersion, {
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { fileUuid, fileVersion }
    });
