import { connect } from 'react-redux';
import { AreaSelectionType } from '@he-novation/config/types/area-selection.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import { ATTACHMENT, KEY_WORD } from '@he-novation/paths/modals.constants';
import { isFunction } from 'lodash/fp';
import { compose } from 'recompose';
import withKeyEventListeners from '../../HOC/withKeyEventListeners';
import { FormComment } from './FormComment';

import { TYPE_NOTE } from '$constants/constants.note';
import combineSelectors from '$helpers/combineSelectors';
import {
    areaSelectionReset,
    areaSelectionStartShape,
    areaSelectionToggle
} from '$redux/content/areaSelection/areaSelectionActions';
import { areaSelectionSelector } from '$redux/content/areaSelection/areaSelectionSelectors';
import { pause } from '$redux/content/file/fileActions';
import {
    audioTracksAndActiveAudioTrackSelector,
    castFileUuidSelector,
    fileHighlightedVersionSelector,
    fileTypeSelector,
    fileUuidSelector,
    folderUuidSelector,
    heightAndWidthSelector,
    iframeCaptureSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import { selectedTeamSelector, teamsSelector } from '$redux/content/projects/projectsSelector';
import { openModal } from '$redux/route/routeActions';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { fetchAccount, fetchUser } from '$redux/user/userActions';
import { currentUserSelector } from '$redux/user/userSelectors';

const mapStateToProps = combineSelectors(
    routeParamsSelector,
    areaSelectionSelector,
    audioTracksAndActiveAudioTrackSelector,
    teamsSelector(),
    fileUuidSelector,
    castFileUuidSelector,
    fileTypeSelector,
    folderUuidSelector,
    fileHighlightedVersionSelector,
    iframeCaptureSelector,
    selectedTeamSelector,
    heightAndWidthSelector,
    currentUserSelector,
    pageAndPageNumberSelector
);

const mapDispatchToProps = (dispatch) => ({
    openAttachmentsModal: (callback, attachments) =>
        dispatch(
            openModal(ATTACHMENT, { title: __('REACT_ATTACHED_FILES'), callback, attachments })
        ),
    openKeywordsModal: (callback, keywords) =>
        dispatch(
            openModal(
                KEY_WORD,
                { title: __('REACT_KEYWORDS'), callback, keywords, fromNotesPanel: true },
                null,
                true
            )
        ),
    onTypeChange(type) {
        dispatch(areaSelectionReset());
        if (type && (type === TYPE_NOTE.RECTANGLE || type === TYPE_NOTE.DRAW)) {
            dispatch(pause());
            dispatch(
                areaSelectionStartShape(
                    type === TYPE_NOTE.RECTANGLE
                        ? AreaSelectionType.Rectangle
                        : AreaSelectionType.Freehand
                )
            );
        } else {
            dispatch(areaSelectionReset());
            dispatch(areaSelectionToggle(false));
        }
    },
    toggleKeyboardListeners: (active) => dispatch(toggleKeyboardListeners(active)),
    areaSelectionToggle: (suspend) => dispatch(areaSelectionToggle(suspend)),
    areaSelectionStartShape: (shape) => dispatch(areaSelectionStartShape(shape)),
    startRectangle: () => dispatch(areaSelectionStartShape(AreaSelectionType.Rectangle)),
    fetchAccountAndUser: () =>
        new Promise((resolve) => {
            dispatch(
                fetchAccount(
                    () => {
                        dispatch(fetchUser(resolve));
                    },
                    (e) => {
                        console.error(e);
                    }
                )
            );
        })
});

const FormCommentOrTask = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withKeyEventListeners(['enter'])
)(FormComment);

export default FormCommentOrTask;
