import { FileCreateResponse } from '@he-novation/config/types/responses/file.responses';
import { folders as foldersSocket } from '@he-novation/config/utils/sockets/sockets.client';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
import { asyncCompleteMultipartUpload } from '@he-novation/front-shared/async/asset.async';
import { asyncFileCreate, asyncVersionCreate } from '@he-novation/front-shared/async/file.async';
import { filePaths } from '@he-novation/paths/herawApiPaths';
import __ from '@he-novation/utils/i18n';
import { isMimeTypeWhiteListed } from '@he-novation/utils/mimeType.utils';
import axios from 'axios';

import {
    FinishedUpload,
    MultipartPart,
    MultipartUpload,
    MultipartUploadBitrate,
    PendingMultiPartUpload,
    Upload,
    UploadCallback,
    UploadError,
    UploaderState,
    UploadFolder,
    UploadProgress,
    UploadProgression
} from '$helpers/Uploader.types';
import { openFeedbackModal } from '$redux/route/routeActions';
import store from '$redux/store';

const MAX_CONCURRENT_UPLOADS = 5;
const BITRATE_REFRESH_DELAY_MS = 1_000;

export class UploaderV2 {
    public static uploads: MultipartUpload[] = [];
    public static invalidFiles: string[] = [];

    private static timeout: ReturnType<typeof setTimeout>;
    private static partsUploading = 0;
    private static pending: PendingMultiPartUpload[] = [];
    private static finished: FinishedUpload[] = [];
    private static errors: UploadError[] = [];
    private static setStateFunctions: ((state: UploaderState) => void)[] = [];
    private static debounce = false;

    public static register(setState: (state: UploaderState) => void) {
        UploaderV2.setStateFunctions.push(setState);
        setState(UploaderV2.getData());
    }

    public static unregister(setState: (state: UploaderState) => void) {
        UploaderV2.setStateFunctions.splice(UploaderV2.setStateFunctions.indexOf(setState), 1);
    }

    public static resetInvalidFiles() {
        UploaderV2.invalidFiles = [];
    }

    public static async upload(
        {
            uploadGroup,
            file: fileToUpload,
            folder,
            parentFileUuid,
            uploadIndex,
            uploadsTotal
        }: {
            uploadGroup: string;
            file: File;
            folder: UploadFolder;
            parentFileUuid?: string;
            uploadIndex: number;
            uploadsTotal: number;
        },
        {
            createCancelToken,
            debounceProgressMs
        }: {
            onPending?: UploadCallback;
            onPendingStart?: UploadCallback;
            onError?: ErrorCallback;
            createCancelToken?: boolean;
            debounceProgressMs?: number;
        } = {}
    ) {
        const mimeType = await UploaderV2.checkMimeType(fileToUpload);

        if (!mimeType) {
            return;
        }

        const fileCreationBody = {
            uploadGroup,
            folderUuid: folder.uuid,
            size: fileToUpload.size,
            mimeType,
            version: 0,
            name: fileToUpload.name
        };

        let response: FileCreateResponse;
        if (parentFileUuid) {
            response = await asyncVersionCreate(parentFileUuid, fileCreationBody);
        } else {
            response = await asyncFileCreate(fileCreationBody);
        }

        const { links, file, asset, uploadId } = response;

        let total = 0;
        const parts: MultipartPart[] = links.map((link) => {
            const oldTotal = total;
            total = oldTotal + link.size;
            const slice = fileToUpload.slice(oldTotal, total);

            return {
                file: { uuid: file.uuid, version: file.version },
                fileSize: fileToUpload.size,
                url: link.url,
                part: link.part,
                slice
            };
        });
        if (UploaderV2.partsUploading === MAX_CONCURRENT_UPLOADS) {
            UploaderV2.pending.push({
                awsUploadId: uploadId,
                uploadGroup,
                file: fileToUpload,
                assetUuid: asset.uuid,
                fileUuid: file.uuid,
                folder,
                uploadIndex,
                uploadsTotal,
                parts,
                createCancelToken
            });
            return;
        }
        for (const part of parts) {
            if (UploaderV2.partsUploading < MAX_CONCURRENT_UPLOADS) {
                UploaderV2.uploadPart(
                    uploadId,
                    fileToUpload,
                    folder,
                    uploadGroup,
                    uploadIndex,
                    uploadsTotal,
                    asset.uuid,
                    file.uuid,
                    parts,
                    part
                );
            }
        }
    }

    private static async uploadPart(
        awsUploadId: string,
        fileToUpload: File,
        folder: UploadFolder,
        uploadGroup: string,
        uploadIndex: number,
        uploadsTotal: number,
        assetUuid: string,
        fileUuid: string,
        parts: MultipartPart[],
        part: MultipartPart
    ) {
        UploaderV2.partsUploading++;

        let upload: MultipartUpload | undefined = UploaderV2.uploads.find(
            (u) => u.uploadGroup === uploadGroup && u.uploadIndex === uploadIndex
        );
        const now = Date.now();
        if (!upload) {
            upload = {
                awsUploadId,
                assetUuid,
                fileUuid,
                uploadGroup,
                file: fileToUpload,
                folder,
                uploadIndex,
                uploadsTotal,
                parts,
                bitrate: {
                    depth: 10,
                    refreshDelay: BITRATE_REFRESH_DELAY_MS,
                    uploadHistory: []
                }
            };
            UploaderV2.uploads.push(upload);
        }

        part.uploadProgression = {
            bitrate: 0,
            lastTick: now,
            loaded: 0,
            progress: 0,
            startTime: now,
            total: part.slice!.size,
            remainingMs: null
        };

        axios
            .put(part.url, part.slice, {
                onUploadProgress: (e) => {
                    if (!part.uploadProgression) throw new Error('Missing upload');
                    const now = Date.now();
                    const total = e.total || part.uploadProgression.total;
                    const elapsed = now - part.uploadProgression.lastTick;

                    part.uploadProgression.bitrate = e.bytes / elapsed; // bytes per MS
                    part.uploadProgression.remainingMs =
                        (total - e.loaded) / part.uploadProgression.bitrate; //ms
                    part.uploadProgression.loaded = e.loaded;
                    part.uploadProgression.total = total;
                    part.uploadProgression.progress = (e.loaded / total) * 100;
                    part.uploadProgression.lastTick = now;

                    UploaderV2.onProgress(upload, false);
                }
            })
            .then((r) => {
                part.ETag = r.headers.etag;
                UploaderV2.partsUploading--;
                part.uploadProgression!.loaded = part.uploadProgression!.total;

                if (upload.parts.every((p) => p.uploadProgression && p.ETag)) {
                    asyncCompleteMultipartUpload(assetUuid, {
                        uploadId: awsUploadId,
                        parts: parts.map((p) => ({
                            PartNumber: p.part,
                            ETag: p.ETag!
                        }))
                    });
                    UploaderV2.uploads.splice(UploaderV2.uploads.indexOf(upload), 1);
                    UploaderV2.finished.push({
                        uploadGroup,
                        uploadIndex,
                        uploadsTotal,
                        file: fileToUpload,
                        folder,
                        startedAt: upload.parts[0].uploadProgression!.startTime,
                        finishedAt: Date.now()
                    });
                    UploaderV2.onProgress(upload, true);
                }

                let pendingPart: MultipartPart | undefined;
                let inProgressUpload: MultipartUpload | undefined;

                for (const runningUpload of UploaderV2.uploads) {
                    pendingPart = runningUpload.parts.find(
                        (p) => p.uploadProgression === undefined
                    );
                    if (pendingPart) {
                        inProgressUpload = runningUpload;
                        break;
                    }
                }

                if (pendingPart && inProgressUpload) {
                    UploaderV2.uploadPart(
                        inProgressUpload.awsUploadId,
                        inProgressUpload.file,
                        inProgressUpload.folder,
                        inProgressUpload.uploadGroup,
                        inProgressUpload.uploadIndex,
                        inProgressUpload.uploadsTotal,
                        inProgressUpload.assetUuid,
                        inProgressUpload.fileUuid,
                        inProgressUpload.parts,
                        pendingPart
                    );
                } else {
                    const pending = UploaderV2.pending.shift();
                    if (!pending) return;
                    UploaderV2.uploads.push({
                        ...pending,
                        bitrate: {
                            depth: 10,
                            refreshDelay: BITRATE_REFRESH_DELAY_MS,
                            uploadHistory: []
                        }
                    });
                    for (const part of pending.parts) {
                        if (UploaderV2.partsUploading < MAX_CONCURRENT_UPLOADS) {
                            UploaderV2.uploadPart(
                                pending.awsUploadId,
                                pending.file,
                                pending.folder,
                                pending.uploadGroup,
                                pending.uploadIndex,
                                pending.uploadsTotal,
                                pending.assetUuid,
                                pending.fileUuid,
                                pending.parts,
                                part
                            );
                        }
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                UploaderV2.partsUploading--;
                UploaderV2.uploads.splice(UploaderV2.uploads.indexOf(upload), 1);
                UploaderV2.errors.push({
                    uploadGroup,
                    uploadIndex,
                    uploadsTotal,
                    file: fileToUpload,
                    folder,
                    error: e
                });
            });
    }

    private static onProgress(upload: MultipartUpload, complete: boolean) {
        if (upload.debounce && !complete) return;
        upload.debounce = true;
        UploaderV2.update();
        const u = UploaderV2.multipartUploadToUpload(upload);
        foldersSocket.emit(upload.folder.uuid, 'uploadProgress', {
            assetUuid: upload.assetUuid,
            fileUuid: upload.fileUuid,
            progress: u.progression
        });
        setTimeout(() => (upload.debounce = false), 1000);
    }

    private static async checkMimeType(fileToUpload: File) {
        let mimeType = fileToUpload.type;
        const serverMimeType = await UploaderV2.readMagicBytes(fileToUpload);

        // Remove experimental 'x-' prefix to mime types
        const fixedMimeType = mimeType?.replace('/x-', '/');
        const fixedServerMimeType = serverMimeType?.replace('/x-', '/') || null;

        if (serverMimeType === null || (fixedMimeType && fixedMimeType !== fixedServerMimeType)) {
            UploaderV2.dispatchInvalidFile(fileToUpload.name);
            return;
        }

        if (!mimeType) {
            mimeType = serverMimeType || '';
        }

        if (mimeType && isMimeTypeWhiteListed(mimeType)) {
            return mimeType;
        }

        UploaderV2.dispatchInvalidFile(fileToUpload.name);
        return;
    }

    private static dispatchInvalidFile(filename: string) {
        UploaderV2.invalidFiles.push(filename);

        store.dispatch(
            openFeedbackModal(UploaderV2.invalidFiles.join('<br/>'), 10_000, {
                title: __('ERR_FILE_ILLEGAL_FORMAT'),
                isError: true
            })
        );
    }

    private static async readMagicBytes(file: File): Promise<string | null> {
        try {
            const input = file.slice(0, Math.min(file.size, 1024 * 10));
            const buffer = new Uint8Array(await input.arrayBuffer());
            const data: {
                mimeType: string | null;
            } = await apiFetch(
                filePaths.mimeType,
                {
                    headers: {
                        'content-type': 'application/octet-stream'
                    },
                    method: 'POST',
                    body: buffer,
                    query: { fileName: file.name, mimeType: file.type }
                },
                false
            );
            return data.mimeType || null;
        } catch (e) {
            return null;
        }
    }

    private static multipartUploadToUpload(upload: MultipartUpload): Upload {
        const progress = upload.parts.reduce(
            (acc, part) => {
                const partUploadProgression = part.uploadProgression || {
                    bitrate: 0,
                    loaded: 0,
                    progress: 0,
                    startTime: Infinity,
                    lastTick: 0,
                    total: 0,
                    remainingMs: 0
                };

                return {
                    bitrate: 0,
                    loaded: acc.loaded + partUploadProgression.loaded,
                    progress: 0,
                    startTime: Math.min(acc.startTime, partUploadProgression.startTime),
                    total: part.fileSize,
                    lastTick: Math.max(acc.lastTick, partUploadProgression.lastTick),
                    remainingMs: 0
                };
            },
            {
                bitrate: 0,
                loaded: 0,
                progress: 0,
                startTime: Infinity,
                total: 0,
                lastTick: 0,
                remainingMs: 0
            } as UploadProgression
        );

        const remainingBytes = progress.total - progress.loaded; // bytes
        const bitrate = UploaderV2.computeBitrate(upload.bitrate, progress.loaded); // bytes per ms

        progress.remainingMs = bitrate ? remainingBytes / bitrate : 0; // ms
        progress.bitrate = bitrate;
        progress.progress = (progress.loaded / progress.total) * 100;

        return {
            ...upload,
            progression: progress
        };
    }

    private static computeBitrate(bitrate: MultipartUploadBitrate, loaded: number): number {
        const now = Date.now();

        if (
            bitrate.uploadHistory.length === 0 ||
            now - bitrate.uploadHistory[bitrate.uploadHistory.length - 1].timestamp >
                bitrate.refreshDelay
        ) {
            if (bitrate.uploadHistory.length >= bitrate.depth) {
                bitrate.uploadHistory.shift();
            }

            bitrate.uploadHistory.push({ loaded, timestamp: now });
        }

        const elapsedMs = now - bitrate.uploadHistory[0].timestamp; // ms
        if (elapsedMs > 0) {
            const loadedDiff = loaded - bitrate.uploadHistory[0].loaded; // bytes
            return loadedDiff / elapsedMs; // bytes per ms
        }

        return 0;
    }

    private static getData(): UploaderState {
        return {
            uploads: UploaderV2.uploads.map((u) => UploaderV2.multipartUploadToUpload(u)),
            pending: UploaderV2.pending.concat(),
            finished: UploaderV2.finished.concat(),
            errors: UploaderV2.errors.concat()
        };
    }

    private static update() {
        if (UploaderV2.debounce) return;

        UploaderV2.debounce = true;
        setTimeout(() => {
            const data = UploaderV2.getData();
            UploaderV2.setStateFunctions.forEach((setState) => setState(data));
            UploaderV2.debounce = false;
        }, 1000);
    }
}
