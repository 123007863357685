import {
    BreadCrumb,
    FolderContent,
    FolderMember,
    FolderSizeData,
    FullFolder
} from '@he-novation/config/types/folder.types';
import {
    FolderCopyTreeBody,
    FolderInviteBody,
    FolderModifyAccessBody,
    FolderNotificationBody,
    FolderSpecific,
    FolderUpdateBody
} from '@he-novation/config/types/payloads/folder.payload';
import { ProjectFolderCreateBody } from '@he-novation/config/types/payloads/project.payload';
import paths, {
    folderPaths,
    pathWithParams,
    publicApiV1Paths
} from '@he-novation/paths/herawApiPaths';
import { apiFolderContentToFrontFolderContent } from '../mappers/folder.mappers';
import { FrontFolderContent } from '../types/folder.front-types';
import { apiFetch } from './apiFetch';

export const createFolder = ({ folderUuid, name, copyParentProperties }): Promise<FullFolder> =>
    apiFetch(paths.folder.root, {
        method: 'POST',
        body: {
            name,
            parentFolderUuid: folderUuid,
            copyParentProperties,
            copyParentMembers: true,
            copyParentMembersPending: true
        }
    });
export const fetchRootFolder = () =>
    apiFetch(paths.folder.rootContent, {
        method: 'GET'
    });

export const createProjectFolder = async (
    projectUuid: string,
    { parentFolderUuid, name, teamUuids }: ProjectFolderCreateBody
) =>
    apiFetch(`/hpi/projects/${projectUuid}/folder`, {
        method: 'POST',
        body: {
            name,
            parentFolderUuid,
            teamUuids
        } satisfies ProjectFolderCreateBody
    });

export const fetchFolder = ({
    uuid,
    password
}: {
    uuid: string;
    password?: string;
}): Promise<FullFolder> =>
    apiFetch(
        uuid
            ? pathWithParams(paths.folder.specific, { folderUuid: uuid }, { p: password })
            : paths.folder.root,
        {
            method: 'GET'
        }
    );

export const fetchFolderContent = ({
    uuid,
    shared,
    password
}: {
    uuid?: string;
    shared?: boolean;
    password?: string;
}): Promise<FrontFolderContent> =>
    (shared && !uuid
        ? fetchSharedRoot()
        : apiFetch(
              uuid
                  ? pathWithParams(folderPaths.content, { folderUuid: uuid }, { p: password })
                  : paths.folder.rootContent,
              {
                  method: 'GET'
              }
          )
    ).then(apiFolderContentToFrontFolderContent);

export const fetchSharedRoot = () =>
    apiFetch(paths.folder.shared, {
        method: 'GET'
    });

export const asyncFolderUpdate = (folderUuid: string, body: FolderUpdateBody) =>
    apiFetch(paths.folder.specific, {
        method: 'PATCH',
        params: { folderUuid } satisfies FolderSpecific,
        body
    });

export const asyncFolderFetchNotificationSettings = (folderUuid: string) =>
    apiFetch(folderPaths.notifications, {
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderUpdateNotificationSettings = (
    folderUuid: string,
    body: FolderNotificationBody
) =>
    apiFetch(folderPaths.notifications, {
        method: 'PATCH',
        params: { folderUuid } satisfies FolderSpecific,
        body
    });

export const asyncFolderMembersFetch = (uuid: string, pending?: boolean): Promise<FolderMember[]> =>
    apiFetch(`hpi/folder-members/${uuid}?pending=${pending ? 1 : 0}`, {
        method: 'GET'
    }).then((r) =>
        r.map((m) => ({
            ...m,
            download: m.download,
            export: m.export,
            created: new Date(m.created)
        }))
    );

export const fetchFolderSize = (
    folderUuid: string,
    {
        publicSize,
        password,
        flat
    }: {
        publicSize?: boolean;
        password?: string;
        flat?: boolean;
    } = {}
): Promise<FolderSizeData> =>
    apiFetch(
        pathWithParams(
            folderPaths.size,
            { folderUuid },
            { publicSize: publicSize ? 1 : undefined, password, flat: flat ? 1 : undefined }
        ),
        {
            method: 'GET'
        }
    );

export const fetchFolderPlugins = ({ uuid }: { uuid: string }) =>
    apiFetch(pathWithParams(folderPaths.plugins, { folderUuid: uuid } satisfies FolderSpecific));

export const grantAccess = (
    folderUuid: string,
    members: {
        uuid: string;
        role: string;
        canDownload: boolean;
        canExport: boolean;
    }[],
    message?: string | null
) =>
    apiFetch(paths.folder.invite, {
        params: { folderUuid },
        method: 'POST',
        body: {
            members,
            message
        } satisfies FolderInviteBody
    });

export const fetchFolderPath = (folderUuid: string): Promise<BreadCrumb[]> =>
    apiFetch(
        pathWithParams(paths.folder.path, {
            folderUuid
        }),
        {
            method: 'GET'
        }
    );

export const revokeAccess = (folderUuid: string, userUuid?: string) =>
    apiFetch(folderPaths.membersSpecific, {
        params: {
            folderUuid,
            userUuid
        },
        method: 'DELETE'
    });

export const leaveSharedFolder = (folderUuid: string) =>
    apiFetch(folderPaths.leave, {
        method: 'DELETE',
        params: {
            folderUuid
        }
    });

export const fetchFolderTeams = (folderUuid) => apiFetch(`hpi/folder/${folderUuid}/teams`);

export const setFolderTeams = (folderUuid: string, teamUuids: string[]) =>
    apiFetch(`hpi/folder/${folderUuid}/teams`, {
        method: 'PUT',
        body: {
            teamUuids
        }
    });

export const modifyAccess = (
    folderUuid: string,
    userUuid: string,
    { role, canExport, canDownload }: FolderModifyAccessBody
) =>
    apiFetch(pathWithParams(folderPaths.membersSpecific, { folderUuid, userUuid }), {
        method: 'PUT',
        body: {
            role,
            canExport,
            canDownload
        }
    });

export const folderRecursiveSettingsSet = ({
    public_download,
    public_password,
    export_mode,
    default_presets,
    uuid,
    tags,
    labels,
    ...folder
}) => {
    return apiFetch(pathWithParams(paths.folder.recursive, { folderUuid: uuid }), {
        method: 'PUT',
        body: JSON.stringify({
            folder: {
                ...folder,
                tags: tags ? (Array.isArray(tags) ? tags : tags.split(',').filter((v) => v)) : [],
                labels: labels
                    ? Array.isArray(labels)
                        ? labels
                        : labels.split(',').filter((v) => v)
                    : [],
                default_presets,
                public_download,
                public_password,
                export_mode
            }
        })
    });
};

export const asyncFolderCopyTree = (
    sourceFolderUuid: string,
    targetFolderUuid: string,
    { name, renameIfExists }: { name?: string; renameIfExists?: boolean } = {}
) =>
    apiFetch(folderPaths.copyTree, {
        method: 'POST',
        body: {
            sourceFolderUuid,
            targetFolderUuid,
            name,
            renameIfExists
        } satisfies FolderCopyTreeBody
    });

export const folderMove = (sourceUuid: string, destinationUuid: string) =>
    apiFetch(
        pathWithParams(paths.folder.move, {
            sourceFolderUuid: sourceUuid,
            targetFolderUuid: destinationUuid
        }),
        {
            method: 'PUT'
        }
    );

export const folderDelete = (uuid: string) =>
    apiFetch(folderPaths.specific, {
        params: { folderUuid: uuid },
        method: 'DELETE'
    });

export const folderTrashFetch = () => apiFetch(`api/folder/get/trash`, { method: 'POST' });

export const asyncFolderRestore = (folderUuid: string) =>
    apiFetch(folderPaths.restore, {
        method: 'POST',
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderSharedWith = (userUuid: string) =>
    apiFetch(pathWithParams(paths.folder.sharedWith, { userUuid }), {
        method: 'GET'
    });

export const asyncOpenApiFolderFetch = (apiKey: string, folderUuid: string) =>
    apiFetch(publicApiV1Paths.folder.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { folderUuid }
    });

export const asyncOpenApiFolderContentFetch = (
    apiKey: string,
    folderUuid: string,
    password?: string
): Promise<FolderContent> =>
    apiFetch(publicApiV1Paths.folderContent.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { folderUuid },
        query: { p: password }
    });
