import SidePanelNotes from './SidePanelNotes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
    fileTypeSelector,
    fileUuidSelector,
    folderUuidSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import combineSelectors from '../../../helpers/combineSelectors';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { currentProjectUuidSelector } from '$redux/content/projects/projectsSelector';
import { preferencesSelector } from '$redux/user/userSelectors';

const mapStateToProps = combineSelectors(
    pageAndPageNumberSelector,
    currentProjectUuidSelector,
    folderUuidSelector,
    routeParamsSelector,
    fileUuidSelector,
    folderRoleSelector,
    preferencesSelector,
    fileTypeSelector
);

export default compose(connect(mapStateToProps))(SidePanelNotes);
