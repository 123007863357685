import { contentFileSchema, FileGhost } from './file.types';
import { z } from 'zod';
import { LegacyProfile, UserPrefs } from './user.types';

export type FolderMetadataAdmittance = {
    role: string;
    download: 1 | 0 | '0' | '1' | boolean;
    export: 1 | 0 | '0' | '1' | boolean;
};

export enum FolderRole {
    ROLE_OWNER = 'owner',
    ROLE_MANAGER = 'ROLE_MANAGER',
    ROLE_REPORTER = 'ROLE_REPORTER',
    ROLE_PROVIDER = 'ROLE_PROVIDER',
    ROLE_CONTRIBUTOR = 'ROLE_CONTRIBUTOR',
    ROLE_FOLLOWER = 'ROLE_FOLLOWER',
    ROLE_SPECTATOR = 'ROLE_SPECTATOR',
    ROLE_PUBLIC = 'ROLE_PUBLIC'
}

const folderLiveNotifySchema = z.object({
    access_grant: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    comment_add: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_download: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_export: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_new: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_set_final: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional()
});

export type FolderLiveNotify = z.infer<typeof folderLiveNotifySchema>;

export const folderMetadataUserSchema = z.object({
    livenotify: folderLiveNotifySchema.optional()
});
export type FolderMetadataUser = z.infer<typeof folderMetadataUserSchema>;

export const folderMetadataRefSchema = z.object({
    name: z.string(),
    parent_uuid: z.string()
});
export const admittanceSchema = z.object({
    role: z.string(),
    download: z.union([z.number(), z.string(), z.boolean()]),
    export: z.union([z.number(), z.string(), z.boolean()])
});

export const folderMetadataSchema = z.object({
    icon: z.string().optional(),
    cover: z.string().optional(),
    admittance: z.union([admittanceSchema, z.literal(false)]).optional(),
    user: folderMetadataUserSchema.optional(),
    notes: z.string().optional(),
    ref: folderMetadataRefSchema.optional()
});
export type FolderMetadata = z.infer<typeof folderMetadataSchema>;

export type BaseFolderType = {
    /**
     * @TJS-format uuid
     */
    uuid: string;
    name: string;
};

export const folderContentFileSchema = z
    .object({
        type: z.literal('file'),
        created: z.date().nullable(),
        encoding: z.any().optional().nullable(),
        queued: z.boolean().optional(),
        resolution: z.array(z.number()).optional().nullable(),
        uploadProgress: z.number().optional(),
        castFileUuid: z.string().nullable().optional()
    })
    .merge(contentFileSchema);

export type FolderContentFile = z.infer<typeof folderContentFileSchema>;

const folderSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.nativeEnum(FolderRole),
    created: z.date(),
    updated: z.date().nullable(),
    isPublic: z.boolean(),
    isEncrypted: z.boolean(),
    publicPassword: z.string().nullable().optional(),
    publicDownload: z.boolean(),
    canDownload: z.boolean(),
    canExport: z.boolean(),
    labels: z.array(z.string()),
    tags: z.array(z.string()),
    admittance: z.boolean(),
    castTriggers: z.array(
        z.object({
            castUid: z.string(),
            castName: z.string(),
            castFolderUuid: z.string().nullable().optional()
        })
    ),
    userMetadata: folderMetadataUserSchema.optional(),
    folder: z
        .object({
            uuid: z.string(),
            name: z.string(),
            isPublic: z.boolean()
        })
        .nullable()
        .optional(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string(),
            isProjectRoot: z.boolean(),
            folderUuid: z.string()
        })
        .nullable()
        .optional()
});
export type Folder = z.infer<typeof folderSchema>;

export const fullFolderSchema = z
    .object({
        isSharedRoot: z.boolean().optional(),
        metadata: z.object({
            icon: z.string().optional(),
            cover: z.string().optional(),
            admittance: z.union([admittanceSchema, z.literal(false)]).optional(),
            user: folderMetadataUserSchema.optional(),
            notes: z.string().optional(),
            ref: z
                .object({
                    name: z.string(),
                    parent_uuid: z.string()
                })
                .optional()
        }),
        watermark: z.boolean(),
        watermarkUrl: z.string().nullable().optional(),
        exportMode: z.enum(['never', 'always', 'final']),
        defaultPresets: z.any(),
        expires: z.date().optional().nullable(),
        project: z
            .object({
                uuid: z.string(),
                name: z.string(),
                isProjectRoot: z.boolean(),
                folderUuid: z.string()
            })
            .optional(),
        client: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        triggers: z.array(z.string()),
        user: z.object({
            uuid: z.string(),
            firstname: z.string().optional(),
            lastname: z.string().optional(),
            email: z.string(),
            picture: z.string().nullable().optional(),
            locale: z.string()
        })
    })
    .merge(folderSchema);

export type FullFolder = z.infer<typeof fullFolderSchema>;

export const folderContentFolderSchema = z
    .object({
        type: z.literal('folder'),
        created: z.date().nullable().optional(),
        size: z.number().optional(),
        metadata: z.any().optional()
    })
    .merge(folderSchema);

export type FolderContentFolder = z.infer<typeof folderContentFolderSchema>;

export const folderStateFileSchema = z
    .object({
        created: z.date().nullable(),
        encoding: z.any().optional(),
        queued: z.boolean().optional()
    })
    .merge(folderContentFileSchema);

export type FolderStateFile = z.infer<typeof folderStateFileSchema>;

export const folderContentChildSchema = z.union([
    folderContentFileSchema,
    folderContentFolderSchema
]);
export type FolderContentChild = z.infer<typeof folderContentChildSchema>;
export const folderContentSchema = z.array(folderContentChildSchema);
export type FolderContent = z.infer<typeof folderContentSchema>;

export type FolderMember = LegacyProfile & {
    created: Date;
    canDownload: boolean;
    email: string;
    canExport: boolean;
    role: FolderRole;
    uuid: string;
    pending?: boolean;
    preferences?: UserPrefs;
};

export type FolderMemberWithNotificationSettings = FolderMember & {
    livenotify?: FolderLiveNotify;
    teamUuid?: string;
    teamName?: string;
};

export type LegacyFolderSizeData = {
    folder: {
        count: number;
        files: number;
        size: number;
    };
};

export type FolderSizeData = {
    folders: number;
    files: number;
    size: number;
};

export type FolderSharedRoot = {
    isSharedRoot: true;
    name: string;
    folder: null;
    isPublic: false;
    uuid: null;
    castTriggers: [];
    labels: [];
};

export type BreadCrumb = {
    uuid: string;
    name: string;
    project: { uuid: string; name: string; rootFolderUuid?: string | null } | null;
};

export type RecursiveFolder = {
    name: string;
    children: RecursiveFolder[];
};

export type CreateFolderData = {
    parentFolderUuid?: string | null;
    copyParentProperties?: boolean;
    name: string;
    projectUuid?: string | null;
    copyParentMembers?: boolean;
    copyParentMembersPending?: boolean;
    labels?: string[];
};

export enum TriggerName {
    Bundles = 'bundles',
    Metadata = 'metadata',
    Thumbnail = 'thumbnail',
    Player = 'player',
    Preview = 'preview'
}

export enum TriggerHandler {
    UploadBefore = 'upload_before',
    UploadAfter = 'upload_after',
    Encode = 'encode'
}

export type FolderShare = {
    canDownload: boolean;
    canExport: boolean;
    role: FolderRole;
    folder: { uuid: string; name: string };
    user: {
        uuid: string;
        email: string;
    };
};
