import { connect } from 'react-redux';
import type { Comment } from '@he-novation/config/types/note.types';
import { TASK_FORM } from '@he-novation/paths/modals.constants';
import { compose, withProps } from 'recompose';
import combineSelectors from '../../../helpers/combineSelectors';
import withSocketIO from '../../HOC/withSocketIO';
import SidePanelNote from './SidePanelNote';

import { isEqualDate } from '$helpers/datetime';
import { localeSelector } from '$redux/config/configSelectors';
import {
    areaSelectionDisplay,
    areaSelectionReset
} from '$redux/content/areaSelection/areaSelectionActions';
import {
    iframeCaptureSet,
    pause,
    setActiveAudioTrack,
    setActiveSubtitles,
    setPage
} from '$redux/content/file/fileActions';
import {
    fileThumbnailAndNameSelector,
    fileTypeSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import { folderPluginsSelector } from '$redux/content/folder/folderSelectors';
import {
    currentProjectUuidSelector,
    isProjectManagerSelector
} from '$redux/content/projects/projectsSelector';
import { openModal } from '$redux/route/routeActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

const mapStateToProps = combineSelectors(
    currentProjectUuidSelector,
    currentUserUuidSelector,
    fileTypeSelector,
    fileThumbnailAndNameSelector,
    folderPluginsSelector,
    isProjectManagerSelector,
    localeSelector,
    pageAndPageNumberSelector
);

const mapDispatchToProps = (dispatch) => {
    return {
        openEditTaskModal: (task) =>
            dispatch(openModal(TASK_FORM, null, { uuid: task.uuid }, true)),
        setActiveAudioTrack: (activeAudioTrack) => dispatch(setActiveAudioTrack(activeAudioTrack)),
        setActiveSubtitles: (activeSubtitles) => dispatch(setActiveSubtitles(activeSubtitles)),
        setIframeCapture: (iframeCapture) => dispatch(iframeCaptureSet(iframeCapture)),
        setPage: (page) => dispatch(setPage(page)),
        areaSelectionDisplay: (data) => dispatch(areaSelectionDisplay(data)),
        areaSelectionReset: () => dispatch(areaSelectionReset())
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSocketIO())(SidePanelNote);
