import './DataLayout.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import {
    AbsoluteMenu,
    useAbsoluteMenu
} from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu';
import { Theme } from '@he-novation/design-system/enums';
import { useForwardedRef } from '@he-novation/design-system/hooks/useForwardedRef';
import Icon from '@he-novation/icons';
import cn from 'classnames';
import debounce from 'lodash/fp/debounce';
import { useWindowing, Windowing, WindowingOptions } from '../../hooks/useWindowing';

import { DataLayoutChildren } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutChildren';
import { DataLayoutSorter } from '$components/DataLayout/components/DataLayoutSorter/DataLayoutSorter';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    ItemComponent,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import { BackgroundActionMenu, DataLayoutGroupBy } from '$components/DataLayout/DataLayout.types';
import Empty from '$components/Empty/Empty';
import SelectableGroup from '$components/Selectable/SelectableGroup';
import type { SelectionOptions } from '$components/Selectable/SelectionHandler';
import { activeSidePanelSelector } from '$redux/sideMenu/sideMenuSelectors';

export type DataLayoutProps<T, U extends SelectionAtom | undefined, V> = {
    id: string;
    className?: string;
    items: T[];
    itemToId: (item: T) => string;
    displayMode?: DataLayoutDisplayMode;
    groupBy?: DataLayoutGroupBy<T>;
    saveClosedGroupKeys?: string[];
    windowed?: {
        itemHeight: number | ((displayMode: DataLayoutDisplayMode) => number);
        itemWidth:
            | number
            | ((displayMode: DataLayoutDisplayMode, dataLayoutWidth: number) => number);
        itemsPerRow?: number | ((dataLayoutWidth: number) => number);
        minRows?: number;
    };
    columns: DataLayoutColumn<T, U, V>[];
    selectionOptions?: SelectionOptions;
    onActiveColumnsChange?: (columns: DataLayoutColumn<T, U, V>[]) => void;
    activeColumns?: string[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    ActionsMenuComponent?: React.ComponentType;
    backgroundActionsMenu?: BackgroundActionMenu<T>;
    BackgroundActionsMenuComponent?: React.ComponentType;
    hideHeader?: boolean;
    selectionAtom: U;
    extra: V;
    initialScroll?: number | string | null;
    onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
    onSort?: (column: DataLayoutColumn<T, U, V>, reversed: boolean) => void;
    sort?: [col: string, reverse: boolean] | null;
    onDrop?: (e: DragEvent) => void;
};

export function accessibilityRole(displayMode: DataLayoutDisplayMode, role: string) {
    return displayMode === DataLayoutDisplayMode.List ? role : undefined;
}

function computeItemList<T, U extends SelectionAtom | undefined, V>(
    items: T[],
    sorting?: [DataLayoutColumn<T, U, V>, boolean] | null,
    windowing?: Windowing | null
) {
    let sortedItems: T[];
    if (sorting) {
        sortedItems = items.concat().sort(sorting[0].sort);
        if (sorting[1]) {
            sortedItems.reverse();
        }
    } else {
        sortedItems = items;
    }
    return windowing
        ? sortedItems.slice(windowing.visibleRange[0], windowing.visibleRange[1] + 1)
        : sortedItems;
}

const getLocalStorageKey = (id: string) => `${id}-closed-grouper-keys`;
function parseLocalStorageClosedGroups(id: string): string[] {
    const closedGroupKeysString = localStorage.getItem(getLocalStorageKey(id));
    return closedGroupKeysString ? JSON.parse(closedGroupKeysString) : [];
}

function saveLocalStorageClosedGroup(id: string, key: string, closed: boolean) {
    const closedGroupKeys = parseLocalStorageClosedGroups(id);
    if (closed) closedGroupKeys.push(key);
    else closedGroupKeys.splice(closedGroupKeys.indexOf(key), 1);
    localStorage.setItem(getLocalStorageKey(id), JSON.stringify(closedGroupKeys));
}
function DataLayoutInner<T, U extends SelectionAtom | undefined, V>(
    {
        id,
        className,
        items,
        displayMode = DataLayoutDisplayMode.Grid,
        groupBy,
        saveClosedGroupKeys,
        windowed: _windowed,
        columns,
        ItemGridComponent,
        selectionOptions,
        onActiveColumnsChange,
        activeColumns: _activeColumns,
        hideHeader,
        extra,
        onScroll,
        initialScroll,
        itemToId,
        backgroundActionsMenu,
        ActionsMenuComponent,
        BackgroundActionsMenuComponent,
        onSort,
        selectionAtom,
        sort,
        onDrop
    }: DataLayoutProps<T, U, V>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
    const [width, setWidth] = useState(0);
    const ref = useForwardedRef(forwardedRef);
    const colsRef = useRef<HTMLUListElement>(null);
    const absoluteMenu = useAbsoluteMenu('data-layout-cols-menu');
    const actionsAbsoluteMenu = useAbsoluteMenu('data-layout-actions-menu');
    const [check, setCheck] = useState(false);
    const { activeSidePanel } = useSelector(activeSidePanelSelector);
    const emptyRef = useRef<HTMLDivElement>(null);
    const [sorting, setSorting] = useState<
        [col: DataLayoutColumn<T, U, V>, reversed: boolean] | null
    >(sort ? [columns.find((s) => s.key === sort[0])!, sort[1]] : null);
    const panelOpen = !!activeSidePanel;

    const windowed =
        _windowed &&
        (useMemo(() => {
            return {
                itemWidth:
                    typeof _windowed.itemWidth === 'function'
                        ? _windowed.itemWidth(displayMode, width)
                        : _windowed.itemWidth,
                itemHeight:
                    typeof _windowed.itemHeight === 'function'
                        ? _windowed.itemHeight(displayMode)
                        : _windowed.itemHeight,
                itemsPerRow:
                    displayMode === DataLayoutDisplayMode.Grid
                        ? typeof _windowed.itemsPerRow === 'function'
                            ? _windowed.itemsPerRow(width)
                            : _windowed.itemsPerRow || 4
                        : 1,
                offset: displayMode === DataLayoutDisplayMode.Grid ? [10, 0] : undefined,
                minRows: _windowed.minRows || 10
            } as WindowingOptions;
        }, [width, _windowed, displayMode, panelOpen]) as WindowingOptions | undefined);

    const windowing = useWindowing(ref, !groupBy ? windowed : undefined, items.length);
    const sortedItems = useMemo(
        () => computeItemList(items, sorting, windowing),
        [items, sorting, windowing, windowed, displayMode]
    );
    const [activeColumns, setActiveColumns] = useState<DataLayoutColumn<T, U, V>[]>(
        _activeColumns ? columns.filter((c) => _activeColumns.includes(c.key)) : columns
    );

    const [closedGroupers, setClosedGroupers] = useState<string[]>([]);

    const colMenu = useMemo(
        () =>
            columns
                .filter((c) => !c.unfilterable && c.header)
                .map((col) => {
                    const active = !!activeColumns.find((c) => c.key === col.key);
                    return {
                        children: col.header,
                        className: !active ? 'is-crossed-out' : undefined,
                        onClick: () => {
                            let cols = active
                                ? activeColumns.filter((c) => c.key !== col.key)
                                : [...activeColumns, col];
                            cols = columns.filter((c) => cols.find((col) => col.key === c.key));
                            setActiveColumns(cols);
                            onActiveColumnsChange?.(cols);
                        }
                    };
                }),
        [columns, activeColumns, displayMode]
    );

    useEffect(() => {
        const unregister: (Function | undefined)[] = [];
        const resize = debounce(100, () => {
            if (ref.current) {
                const style = getComputedStyle(ref.current);
                setWidth(
                    ref.current.scrollWidth -
                        parseFloat(style.paddingLeft) -
                        parseFloat(style.paddingRight) -
                        parseFloat(style.borderLeft) -
                        parseFloat(style.borderRight)
                );
            }
        });
        if (ref.current) {
            resize();
        }

        window.addEventListener('resize', resize);
        if (initialScroll && ref.current) {
            ref.current.scrollTop = Number(initialScroll);
        }
        unregister.push(() => window.removeEventListener('resize', resize));
        return () => unregister.forEach((u) => u?.());
    }, []);

    useEffect(() => {
        setClosedGroupers(parseLocalStorageClosedGroups(id));
    }, [groupBy]);

    useEffect(() => {
        const unregister: ((() => void) | undefined)[] = [];
        if (absoluteMenu && colsRef.current) {
            unregister.push(
                absoluteMenu.addRightClickListener(colsRef, colMenu, {
                    usePointerPosition: true
                })
            );
        }
        return () => {
            unregister.forEach((u) => u?.());
        };
    }, [absoluteMenu, colsRef, displayMode, activeColumns, columns]);

    useEffect(() => {
        const elRef = ref.current ? ref : emptyRef;
        if (!elRef.current) return;
        const unregister: (Function | undefined)[] = [];

        if (ActionsMenuComponent && actionsAbsoluteMenu) {
            unregister.push(
                actionsAbsoluteMenu.addRightClickListenerComponent(elRef, ActionsMenuComponent, {
                    delegatedClass: DATA_LAYOUT_CLASSES.CHILD,
                    usePointerPosition: true,
                    getComponentProps: (element) => {
                        const index = element.getAttribute('data-id');
                        if (typeof index === 'undefined' || index === null) {
                            throw new Error('No data-id on data-layout-child element');
                        }
                        return {
                            extra,
                            selectionAtom,
                            item: items.find((i) => itemToId(i) === index),
                            absoluteMenuHandler: actionsAbsoluteMenu
                        };
                    }
                })
            );
        }
        if (BackgroundActionsMenuComponent && actionsAbsoluteMenu) {
            unregister.push(
                actionsAbsoluteMenu.addRightClickListenerComponent(
                    elRef,
                    BackgroundActionsMenuComponent,
                    {
                        excludeClass: DATA_LAYOUT_CLASSES.CHILD,
                        usePointerPosition: true,
                        getComponentProps: () => ({
                            selectionAtom,
                            extra
                        })
                    }
                )
            );
        }
        return () => unregister.forEach((u) => u?.());
    }, [actionsAbsoluteMenu, backgroundActionsMenu, ref.current, emptyRef.current, items]);

    const dataLayoutChildrenProps = {
        columns: activeColumns,
        displayMode,
        selectionOptions,
        ItemGridComponent,
        itemToId,
        computeItemStyle: windowing?.computeItemStyle,
        offset: windowing ? windowing.visibleRange[0] : 0,
        ActionsMenuComponent: ActionsMenuComponent,
        hideHeader,
        selectionAtom,
        extra
    };

    const content = groupBy ? (
        groupBy(sortedItems).map((group) => {
            if (!group.items.length) return null;
            const groupIsClosed = closedGroupers.includes(group.key);
            return (
                <section
                    className="group"
                    key={group.key}
                    role={accessibilityRole(displayMode, 'row')}
                >
                    <header role={accessibilityRole(displayMode, 'rowheader')}>
                        <button
                            className={cn('group-button ', !groupIsClosed && 'is-active')}
                            onClick={() => {
                                {
                                    setClosedGroupers(
                                        groupIsClosed
                                            ? closedGroupers.filter((g) => g !== group.key)
                                            : [...closedGroupers, group.key]
                                    );
                                    if (saveClosedGroupKeys?.includes(group.key)) {
                                        saveLocalStorageClosedGroup(id, group.key, !groupIsClosed);
                                    }
                                }
                            }}
                        >
                            <Icon icon="arrow-left" />
                            {typeof group.header === 'function'
                                ? group.header(group.key, group.items)
                                : group.header}
                            <span className="results">{group.items.length}</span>
                        </button>
                    </header>
                    {!groupIsClosed && (
                        <DataLayoutChildren items={group.items} {...dataLayoutChildrenProps} />
                    )}
                </section>
            );
        })
    ) : (
        <DataLayoutChildren
            items={sortedItems}
            {...dataLayoutChildrenProps}
            style={windowing?.itemWrapperStyle}
        />
    );

    const fullContent = !items.length ? (
        <Empty ref={emptyRef} />
    ) : (
        <>
            {displayMode === DataLayoutDisplayMode.List && !hideHeader && (
                <>
                    <ul role="row" className="data-layout-cols" ref={colsRef}>
                        {!selectionAtom && (
                            <li className="data-layout-select-all">
                                <FormField
                                    id="data-layout-select-all"
                                    type="checkbox"
                                    label={' '}
                                    theme={Theme.Dark}
                                    checked={check}
                                    onChange={() => {
                                        setCheck(!check);
                                    }}
                                />
                            </li>
                        )}
                        {activeColumns.map((column) => {
                            return (
                                <li
                                    key={column.key}
                                    role={'columnheader'}
                                    className={`data-layout-col-${column.key.replace(/\./g, '-')}`}
                                    style={{
                                        width: column.width,
                                        flex: `${column.grow ? 1 : 0} 0 ${
                                            column.width ? `${column.width}px` : ''
                                        }`
                                    }}
                                >
                                    {column.sort ? (
                                        <button
                                            className={cn(
                                                'cell-content',
                                                'sorting-button',
                                                sorting?.[0].key === column.key &&
                                                    (sorting[1] ? 'desc' : 'asc')
                                            )}
                                            onClick={() => {
                                                const desc = !!(sorting && !sorting[1]);
                                                setSorting([column, desc]);
                                                onSort?.(column, desc);
                                            }}
                                        >
                                            {column.header}
                                            <Icon icon="arrow-left" />
                                        </button>
                                    ) : (
                                        <div className="cell-content">{column.header}</div>
                                    )}
                                </li>
                            );
                        })}
                        {ActionsMenuComponent && <li className="actions-cell"></li>}
                    </ul>
                    <AbsoluteMenu id="data-layout-cols-menu" />
                </>
            )}
            {content}
        </>
    );

    const wrapperProps = {
        id: id,
        className: cn(
            DATA_LAYOUT_CLASSES.WRAPPER,
            `is-${displayMode}`,
            windowing && `is-windowing`,
            className
        ),
        role: accessibilityRole(displayMode, 'table'),
        onScroll,
        style: windowing && !groupBy ? windowing.scrollContainerStyle : { overflow: 'auto' }
    };

    return selectionAtom ? (
        <SelectableGroup
            selectionAtom={selectionAtom}
            childrenClassName={DATA_LAYOUT_CLASSES.CHILD}
            noAreaSelection={displayMode === DataLayoutDisplayMode.List}
            DraggedComponent={selectionOptions?.DraggedComponent}
            onItemDrop={selectionOptions?.onItemDrop}
            {...wrapperProps}
            wrapperRef={ref}
        >
            {displayMode === DataLayoutDisplayMode.Grid && (
                <DataLayoutSorter
                    columns={columns}
                    sorting={sorting}
                    setSorting={setSorting}
                    onSort={onSort}
                />
            )}
            {fullContent}
            <AbsoluteMenu id="data-layout-actions-menu" />
        </SelectableGroup>
    ) : (
        <div {...wrapperProps} ref={ref}>
            {displayMode === DataLayoutDisplayMode.Grid && (
                <DataLayoutSorter
                    columns={columns}
                    sorting={sorting}
                    setSorting={setSorting}
                    onSort={onSort}
                />
            )}
            {fullContent}
            <AbsoluteMenu id="data-layout-actions-menu" />
        </div>
    );
}
export const DataLayout = React.forwardRef(DataLayoutInner);
