import './SidePanelFolderMembers.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { acl, ROLE_MANAGER } from '@he-novation/config/utils/acl';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';
import { folderLink, rightsLink } from '@he-novation/paths/herawFrontUris';
import { CONFIRM, FOLDER_SHARE, VIEWS } from '@he-novation/paths/modals.constants';
import Link from '../../router/Link';

import MemberList from '$components/lists/MemberList/MemberList';
import { localeSelector } from '$redux/config/configSelectors';
import { hasPluginOrFolderPluginSelector } from '$redux/content/contentSelectors';
import {
    folderAccessModify,
    grantFolderAccess,
    revokeAccess
} from '$redux/content/folder/folderActions';
import { folderMembersSelector } from '$redux/content/folder/folderSelectors';
import { closeModal, openModal, set } from '$redux/route/routeActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

type SidePanelFolderMembersProps = {
    payload: {
        folderUuid: string;
        shared: boolean;
        role: string;
        userUuid: string;
    };
};

function openModalConfirmRemoveMember(
    dispatch: ReturnType<typeof useDispatch>,
    folderUuid: string,
    userUuid: string,
    isSelf: boolean,
    onRevokeAccess?: (() => void) | null
) {
    return dispatch(
        openModal(
            CONFIRM,
            {
                title: __('POPIN_DELETE_TXT_CONFIRM_MEMBERS_CONFIRM'),
                alert: true,
                message: __(
                    isSelf
                        ? 'POPIN_DELETE_TXT_CONFIRM_REVOKE_MY_ACCESS'
                        : 'POPIN_DELETE_TXT_CONFIRM_MEMBERS'
                ),
                confirmText: __('POPIN_DELETE_TXT_CONFIRM_MEMBERS_CONFIRM'),
                onSubmit: () => {
                    dispatch(revokeAccess(folderUuid, userUuid));
                    dispatch(closeModal());
                    onRevokeAccess?.();
                }
            },
            null,
            true
        )
    );
}

export default function SidePanelFolderMembers({
    payload: { folderUuid, shared, role, userUuid }
}: SidePanelFolderMembersProps) {
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { folderMembers } = useSelector(folderMembersSelector) as {
        folderMembers: {
            uuid: string;
            email: string;
            role: FolderRole;
            canExport: boolean;
            canDownload: boolean;
            pending?: boolean;
        }[];
    };
    const { locale } = useSelector(localeSelector);
    const { hasPluginRoles } = useSelector(hasPluginOrFolderPluginSelector('roles', shared));
    const { hasPluginPublic } = useSelector(hasPluginOrFolderPluginSelector('public', shared));
    const dispatch = useDispatch();
    return (
        <div id="c-side-panel-folder-members">
            <SidePanelHeader
                title={__('MEMBERS')}
                buttons={[
                    acl.folders.displayStats(role as FolderRole) && {
                        icon: 'pie-chart',
                        tone: ButtonTone.Hoverable,
                        onClick: () =>
                            dispatch(
                                openModal(VIEWS, {
                                    folderUuid
                                })
                            )
                    },
                    acl.folders.share(role as FolderRole) && {
                        icon: 'users-add',
                        tone: ButtonTone.Hoverable,
                        onClick: () =>
                            dispatch(
                                openModal(FOLDER_SHARE, { folderUuid, secure: true }, null, true)
                            )
                    },
                    {
                        icon: 'question',
                        tone: ButtonTone.Hoverable,
                        href: rightsLink(locale),
                        external: true,
                        target: '_blank'
                    }
                ]}
            />
            {folderMembers && folderMembers.length > 0 ? (
                <MemberList
                    members={folderMembers}
                    ownerUuid={userUuid}
                    editable={acl.folders.share(role as FolderRole)}
                    displayEmail={false}
                    roles={(role === ROLE_MANAGER || currentUserUuid === userUuid
                        ? acl.getAvailableRoles(hasPluginRoles, hasPluginPublic)
                        : acl.content.showRoles()
                    ).map((r) => ({ label: __(r), value: r }))}
                    onDelete={(m) =>
                        openModalConfirmRemoveMember(
                            dispatch,
                            folderUuid,
                            m.uuid,
                            currentUserUuid === m.uuid,
                            () => {
                                if (m.uuid === currentUserUuid) dispatch(set(folderLink(), true));
                            }
                        )
                    }
                    onDownloadChange={(e, m) =>
                        dispatch(
                            folderAccessModify(folderUuid, m.uuid, {
                                role: m.role,
                                canExport: !!m.canExport,
                                canDownload: e.currentTarget.checked
                            })
                        )
                    }
                    onExportChange={(e, m) =>
                        dispatch(
                            folderAccessModify(folderUuid, m.uuid, {
                                role: m.role,
                                canExport: e.currentTarget.checked,
                                canDownload: !!m.canDownload
                            })
                        )
                    }
                    onRoleChange={(e, m) =>
                        dispatch(
                            folderAccessModify(folderUuid, m.uuid, {
                                role: e.currentTarget.value,
                                canExport: !!m.canExport,
                                canDownload: !!m.canDownload
                            })
                        )
                    }
                    onAcceptAdmittance={(member) =>
                        dispatch(grantFolderAccess(folderUuid, [member]))
                    }
                    onRejectAdmittance={(member) =>
                        openModalConfirmRemoveMember(
                            dispatch,
                            folderUuid,
                            member.uuid,
                            shared,
                            null
                        )
                    }
                />
            ) : (
                <div className="no-content">
                    <p dangerouslySetInnerHTML={{ __html: __('FOLDER_MEMBERS_NO_CONTENT') }} />
                    <Button
                        className="share"
                        tone={ButtonTone.Primary}
                        onClick={() =>
                            dispatch(
                                openModal(FOLDER_SHARE, { folderUuid, secure: true }, null, true)
                            )
                        }
                    >
                        {__('SHARE')}
                    </Button>

                    <Link href={rightsLink(locale)} target="_blank" external>
                        {__('MEMBER_RIGHTS')}
                    </Link>
                </div>
            )}
        </div>
    );
}
