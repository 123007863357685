import './DataLayoutListItem.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { useAbsoluteMenu } from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu';
import { Theme } from '@he-novation/design-system/enums';
import { getLocalizedDate } from '@he-novation/design-system/utils/datetime';
import { getParentWithClass } from '@he-novation/design-system/utils/dom/parentHasClass';
import Icon from '@he-novation/icons';
import cn from 'classnames';
import { property } from 'lodash/fp';

import { useDataLayoutChildSelection } from '$components/DataLayout/components/DataLayoutChildren/useDataLayoutChildSelection';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import { localeSelector } from '$redux/config/configSelectors';

type DataLayoutItemListProps<T, U extends SelectionAtom | undefined, V> = {
    item: T;
    id: string;
    columns: DataLayoutColumn<T, U, V>[];
    selectionAtom: U;
    ActionsMenuComponent?: React.ComponentType<any>;
    extra: V;
    className: string;
};

export function DataLayoutListItem<T, U extends SelectionAtom | undefined, V>({
    item,
    columns,
    id,
    selectionAtom,
    ActionsMenuComponent,
    extra,
    className
}: DataLayoutItemListProps<T, U, V>) {
    const { locale } = useSelector(localeSelector);
    const absoluteMenu = ActionsMenuComponent && useAbsoluteMenu('data-layout-actions-menu');

    const { isSelected, toggle, shiftSelect } = useDataLayoutChildSelection(selectionAtom);
    return (
        <ul
            className={cn('data-layout-list-item', isSelected(id) && 'is-selected', className)}
            role="row"
            id={id}
        >
            {selectionAtom && (
                <li style={{ flex: '0 0 55px' }}>
                    <FormField
                        key={`data-layout-list-item-checkbox-${id}`}
                        id={`data-layout-list-item-checkbox-${id}`}
                        formId="data-layout-list-item-checkbox"
                        className="ignore-mouseup data-layout-item-checkbox"
                        type="checkbox"
                        theme={Theme.Dark}
                        label={' '}
                        checked={isSelected(id)}
                        wrapperProps={{
                            onClick: (e) => {
                                if (e.shiftKey) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const wrapper = getParentWithClass(
                                        e.target,
                                        DATA_LAYOUT_CLASSES.WRAPPER
                                    ) as HTMLElement;

                                    const items = Array.from(
                                        wrapper?.getElementsByClassName(DATA_LAYOUT_CLASSES.CHILD)
                                    ) as HTMLElement[];
                                    shiftSelect(items, id);
                                }
                            }
                        }}
                        onChange={() => {
                            if (selectionAtom) {
                                toggle(id);
                            }
                        }}
                    />
                </li>
            )}
            {columns.map((column) => {
                const value = property(column.key)(item);
                let content: React.ReactNode;
                if (typeof column.render !== 'undefined')
                    content = column.render(item, selectionAtom, extra);
                else if (value instanceof Date) content = getLocalizedDate(value, locale);
                else if (typeof value === 'boolean')
                    content = (
                        <Button
                            className="data-layout-boolean-button"
                            tone={ButtonTone.Hoverable}
                            icon={value ? 'check' : 'cross2'}
                            disabled
                        />
                    );
                else if (typeof value === 'number') content = value !== 0 ? value : null;
                else content = value;
                return (
                    <li
                        key={column.key}
                        role="cell"
                        className={cn(
                            `data-layout-col-${column.key.replace(/\./g, '-')}`,
                            column.grow && 'left'
                        )}
                        style={{
                            width: column.width,
                            flex: `${column.grow ? 1 : 0} 0 ${
                                column.width ? `${column.width}px` : ''
                            }`
                        }}
                    >
                        <div className="cell-content">
                            {content}
                            {!!content && column.icon && <Icon icon={column.icon} />}
                        </div>
                    </li>
                );
            })}
            {ActionsMenuComponent && (
                <li className="actions-cell">
                    <AbsoluteContentButton
                        className="action-cell-inner rotate-icon-90"
                        closeOnClickOutside
                        triggersOnClick
                        triggersOnHover={false}
                        tagName={Button}
                        content={
                            <ActionsMenuComponent
                                item={item}
                                selectionAtom={selectionAtom}
                                extra={extra}
                                absoluteMenuHandler={absoluteMenu}
                            />
                        }
                        icon="three-dots"
                        tone={ButtonTone.Hoverable}
                    />
                </li>
            )}
        </ul>
    );
}
