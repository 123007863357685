import React, { ReactNode } from 'react';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { PrimitiveAtom } from 'jotai';

export type SelectionAtom = PrimitiveAtom<string[]>;

export enum DataLayoutDisplayMode {
    List = 'list',
    Grid = 'grid'
}

export type DataLayoutGroup<T> = {
    key: string;
    header: ReactNode | ((key: string, items: T[]) => ReactNode);
    items: T[];
};

export type DataLayoutColumn<T, U extends SelectionAtom | undefined, V> = {
    key: string;
    unfilterable?: boolean;
    maxWidth?: number;
    grow?: boolean;
    width: number;
    header?: ReactNode;
    sort?: (a: T, b: T) => number;
    render?: (item: T, selectionAtom: U, extra: V) => ReactNode;
    icon?: string;
};
export type ItemComponentProps<T, U extends SelectionAtom | undefined, V> = {
    id: string;
    item: T;
    extra: V;
    selectionAtom: U;
};
export type ItemComponent<T, U extends SelectionAtom | undefined, V> = React.ComponentType<
    ItemComponentProps<T, U, V>
>;

export type DataLayoutGroupBy<T> = (items: T[]) => DataLayoutGroup<T>[];

export type BackgroundActionMenu<T> = () => MultiActionsList;

export const DATA_LAYOUT_CLASSES = {
    WRAPPER: 'data-layout',
    CHILD: 'data-layout-child'
};
